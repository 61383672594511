
import React, {  } from "react";
import styled from "styled-components";

export function LandingInfo2({ widget_config }) {
  widget_config = widget_config ? widget_config : {};

  return (  
    <Wrapper>
      <div className="flex-stretch info-main">
        <div className="flex-column info-main-container">
          <div className="info-header">
            <h4>{ widget_config.title }</h4>
          </div>
          <div className="info-body">
            <div className="flex-row info-body-container">
              <div className="i-b-icon">
                <img placeholder="" src={ widget_config.icon_src } alt="" className="i-b-i-img" />
              </div>
              <div className="i-b-txt">
                <p>
                  { widget_config.description }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  flex: 1;
  justify-content: space-between;
  .info-main {
    border-radius: 20px;
    border: 1.5px solid #0f52ba;
    margin: auto;
    padding: 1rem;
    min-width: 20rem;
    height: 15rem;
    align-items: center;
  }
  .info-main-container {
    height: 100%;
  }
  info-header {
    align-self: flex-start;
  }
  .info-body {
    margin: auto;
  }
  .info-body-container > *:not(:last-child){
    padding-right: 1rem;
  }
  .i-b-icon {
    margin: auto;
  }
  .i-b-i-img {
    height: 4rem;
    width: 4rem;
    font-size: 13px;
  }
  .i-b-txt {
  }
  .i-b-txt p {
    font-size: 13px;
    max-width: 20rem;
  }
  @media screen and (max-width: 750px) {
    .info-main {
      height: 18rem;
    }
  }
`

export default LandingInfo2;

