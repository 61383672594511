import React, {
  useState,
  useEffect
} from "react";

import { useNavigate } from "react-router-dom";

import styled from "styled-components";

// assets
import img from "../../../Assets/Images/background.jpg";
import logo from "../../../Assets/Images/moh_logo2.png";

import "./admin.css";

// custom components
import CustomDataGrid from "../../../shared/Charts/CustomDataGrid";
import CreateAccount from "./createAccount";
import UpdateAccount from "./updateAccount";

// utils
import { userListColumns } from "../../../shared/Charts/Constants/AdminConstants";
import { customizeUsers } from "../../../shared/Helpers/DataManipulationHelper";

export default function Admin() {
  const history = useNavigate();
  const userListApi = "/EHPMD_WebApi/GetUsers";
  const [currentTab, setCurrentTab] = useState("Accounts");
  const [tabs, setTabs] = useState(["Accounts", "Create Account"]);
  const [tabData, setTabData] = useState({});

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });

  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
  useEffect(() => (window.onresize = updateSize), []);
  

  function changeTab(tab, tab_data) {
    if(tab != currentTab) {
      tab == "Update Account" 
          ? setTabs(["Accounts", "Create Account", "Update Account"])
          : setTabs(["Accounts", "Create Account"]);
      setCurrentTab(tab);
      setTabData(tab_data);
    }
  }

  const gotoHomePage = () => {
    history("/Dashboard");
  };

  return (
    <Wrapper size={size}>
      <div className="admin">
        <div className="flex-column">
          <div className="flex-item-center">
            <div className="flex-column flex-item-center wrapper-container">
              <div className="flex-stretch logo">
                <img className="logo-img" src={logo} alt="" />
              </div>
              <div className="admin-header">
                <h5>National Supply Chain Dashboard</h5>
              </div>

              <div className="tabs">
                <ul className="flex-row tab-ul-admin">
                  { tabs.map(function (tab) {
                      return (<li className={`tab-li ${ currentTab === tab 
                          ? 'tab-li-active' : null }`}>
                        <a onClick={() => changeTab(tab)}>{tab}</a>
                      </li>
                      );
                  })}
                </ul>
              </div>

              <div>
                { currentTab === "Create Account" &&
                    <CreateAccount />
                }
              </div>

              <div>
                { currentTab === "Update Account" &&
                    <UpdateAccount component_config={{ data: tabData }}/>
                }
              </div>

              <div className="account-grid">
                { currentTab === "Accounts" &&
                  <CustomDataGrid
                    component_config={{
                      columns: userListColumns,
                      data_source_api: userListApi,
                      data_manipulator: customizeUsers,
                      data_source: "fanos",
                      convert_params_to_formData: false,
                      data_property: "",
                      method: "GET",
                      select_filter_config: [ ],
                      defaultFilterParams: {},
                      legend: null,
                      density: "compact",
                      callback_action: changeTab
                    }}
                  />
                }
              </div>
            </div>
        </div>
      </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: ${ props => props.size.x }px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: ${ props => props.size.y }px;

  
  .admin {  
    width: 100%;
    margin-top: 4.8rem !important;
    padding-left: 5.5rem;
    max-width: 1300px;
    margin: auto;
    background: white;
  }

  .logo, .tabs, .admin-header {
    margin: auto;
  }
  
  .logo-img {
    width: 80px;
    height: 80px;
  }

  .account-grid {
    width: 100%
  }
  
  @media screen and (max-width: 750px) {
    .admin {
      padding-left: 0;
    }
  }
`;
