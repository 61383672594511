import { colors } from "@mui/material";

export function ShowProcurementStatusDetail (row) {
    return row.InvoicedQuantity > 0;
}

export function lastSyncDate (checked) {
    if(checked) {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 90);
        return (currentDate.toISOString().slice(0, 10));
    }
    else {
        return undefined;
    }
}

export function LegendColorByLabel (dataLabel, dataLabelInfo) {
    let legendColors = [];
    dataLabel.forEach(element => {
        const match = dataLabelInfo?.find(item => item.label === element);
        legendColors.push(match?.color);
    });
    return legendColors;
}

export function ScrollToElement (element) {
    if (element) {
        // Will scroll smoothly to the top of the next section
        setTimeout(function() {
          element.scrollIntoView({ 
          inline: "start",
          behavior: "smooth",
          alignToTop: false });
        }, 5000);
      }
}

const UIHelper = [
  
]

export default UIHelper;