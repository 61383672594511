
import styled from "styled-components";
import CustomDataGrid from "../../../shared/Charts/CustomDataGrid";

import { 
  importedProductsColumns 
} from "../../../shared/Charts/Constants/CustomDataGridConstants";

export default function ImportedProducts() {
  const importedProductsApi = "/103";

  return (
    <Wrapper>
      <div className="container-context">
        <div className="main-body">
          <div className="main-body-container">
            <CustomDataGrid
              component_config={{
                title: "Imported Products",
                columns: importedProductsColumns,
                data_source_api: importedProductsApi,
                data_source: "eRIS",
                convert_params_to_formData: true,
                data_property: "data",
                select_filter_config: [ ],
                defaultFilterParams: {},
                legend: null,
              }}
            />
        </div>
      </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 1025px) {
    .container-body {
      display: flex;
      flex-direction: column;
    }
  }
`;

const cardSubtitle = {
  margin: "1rem",
  fontSize: "14px",
  color: "#475f7b",
};
