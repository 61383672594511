
import 
  React, 
  { useState } 
from 'react';

import moment 
  from "moment";

// custom
import { 
  IsEmpty 
} from "../Helpers/FormatHelper";

function SimpleDatePicker({ component_config, handle_change }) {
  const [currentTab, setCurrentTab] = useState("All");
  const defaultDateData = [ 
    { label: "1 week", title: "1 week", value: 7 }, 
    { label: "1 month", title: "1 month", value: 30 }, 
    { label: "3 months", title: "3 months", value: 90 }, 
    { label: "1 year", title: "1 year", value: 365 }, 
    { label: "All", title: "All", value: null }
  ]
  component_config = component_config == null ? { } : component_config;
  
  const startDateParamName = IsEmpty(component_config.start_date_param_name) 
      ? "from" 
      : component_config.start_date_param_name;
  const endDateParamName = IsEmpty(component_config.end_date_param_name) 
      ? "to" 
      : component_config.end_date_param_name;

  const handleDateRangeChange = (tab) => {
    setCurrentTab(tab.label);
    handle_change({ 
      [startDateParamName]: tab.value ? moment().subtract(tab.value, "days").format("MM/DD/YYYY") : null,
      [endDateParamName]: tab.value ? moment().format("MM/DD/YYYY") : null
    });
  };

  return (
    <>
      <ul className="flex-row tab-ul" style={{maxWidth: "100%"}}>
        { defaultDateData?.map(function (tab) {
            return (<li className={`tab-li ${ currentTab === tab.label
                ? 'tab-li-active' : null }`}>
              <a title={tab.title} onClick={() => (handleDateRangeChange(tab))}>{tab.label}</a>
            </li>
            );
        })}
      </ul>
    </>
  );
}

export default SimpleDatePicker