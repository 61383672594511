import React, { useEffect, useState } from "react";

import ReactGA from "react-ga4";

import { fetchHubsList } from "../../Services/dashboardService";
import { loginRequest, loginRequestDagu, loginRequestMEMIS } from "../../Services/loginService";

import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
// import LoginPic from "../../Assets/Images/loginImg.jpg";
// import backImage from "../../Assets/Images/mohLogo1.png";

// assets
import logo from "../../Assets/Images/moh_logo2.png";
import "../../shared/Style/Form.css";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import CustomLoadingIndicator from "../../shared/Charts/Plugins/CustomLoadingIndicator";

function LoginForm() {
  const history = useNavigate();
  const [env, setEnv] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error");
  const [showPassword, setShowPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [details, setDetails] = useState({
    username: "",
    password: "",
    deviceIdentifier: "unknown",
    deviceType: "web",
    environmentCode: "",
    hcmisVersion: "unknown",
  });

  useEffect(() => {
    fetchHubsList().then((data) => {
      setEnv(data.data.Data);
    });
  }, []);

  const submitHandler = (e) => {
    setSubmitted(true);
    setError(false);
    loginRequest(details)
      .then((data) => {
        setSuccessMessage(true);
        const userInfo = localStorage.getItem("userInfo");
        userInfo && localStorage.removeItem("userInfo");
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...data,
          })
        );

        ReactGA.gtag("set", "user_properties", {
          FullName: data.FullName,
        });
        
        ReactGA.gtag("set", "user_properties", {
          JobTitle: data.JobTitle,
        });
        
        ReactGA.gtag("set", "user_properties", {
          UserGroup: data.UserTypes?.length > 0 ? data.UserTypes[0].UserType : ""
        });
        
        ReactGA.gtag("set", "user_properties", {
          UserRegion: data.Regions?.length > 0 ? data.Regions[0].Name : ""
        });

        history("/Dashboard");
        setSubmitted(false);

        loginRequestMEMIS().then((MEMISToken) => {
          const userInfoMEMIS = localStorage.getItem("MEMISToken");
          userInfoMEMIS && localStorage.removeItem("MEMISToken");
          localStorage.setItem("MEMISToken", MEMISToken);
        });

        loginRequestDagu().then((daguToken) => {
          const userInfoDagu = localStorage.getItem("daguToken");
          userInfoDagu && localStorage.removeItem("daguToken");
          localStorage.setItem("daguToken", daguToken);
        });
      })
      .catch((err) => {
        setError(true);
        setSubmitted(false);
        
        if(err.response) {
          let reason = err.response;
          switch (reason.status) {
            case 401:
              setErrorMessage("Incorrect username or password");
              break;         
            default:
              setErrorMessage("Unable to connect. Please check your connection. If the problem persists contact technical support.");
              break;
          }
        }
        else {
          setErrorMessage("Unable to connect. Please check your connection. If the problem persists contact technical support.");
        }
        setTimeout(() => {
          setError(false);
        }, 20000);
      });
  };

  return (
    <Wrapper>
      <div>
        <form>
          <div className="rs-form">
            <div className="flex-column flex-center">
              <div className="logo">
                <img className="logo-img" src={logo} alt="" />
              </div>
              <div className="welcome">
                <p style={{ color: "grey" }}>Welcome!</p>
              </div>
              <div className="flex-stretch login-header">
                <h5>National Supply Chain Dashboard</h5>
              </div>

              <div className="rs-form-group">
                <div className="flex-column">
                  <label className="rs-label" htmlFor="username">USERNAME:</label>
                  <input
                    className="rs-input"
                    type="text"
                    name="username"
                    id="username"
                    placeholder="username"
                    onChange={(e) =>
                      setDetails({ ...details, username: e.target.value })
                    }
                    value={details.userName}
                  />
                </div>
              </div>

              <div className="rs-form-group">
                <div className="flex-column">
                  <label className="rs-label" htmlFor="password">PASSWORD:</label>
                  <div className="flex-column">
                    <input
                      className="rs-input"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="password"
                      onChange={(e) =>
                        setDetails({ ...details, password: e.target.value })
                      }
                      value={details.password}
                    />
                    {/* {showPassword ? (
                      <AiOutlineEye className="eye-button" />
                    ) : (
                      <AiOutlineEyeInvisible className="eye-button" />
                    )} */}
                  </div>
                </div>
              </div>

              <div className="rs-form-group">
                <label className="rs-label" htmlFor="site">SITE:</label>
                <select
                  className="rs-input rs-select"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      environmentCode: e.target.value,
                    })
                  }
                >
                  <option value="" className="empty-option">
                    {"Others"}
                  </option>{" "}
                  {env.map((el) => (
                    <option key={el.EnvironmentCode} value={el.EnvironmentCode}>
                      {el.Environment}
                    </option>
                  ))}
                </select>
              </div>

              <div className="rs-feedback rs-feedback-error">
                {error && (
                  <div>
                    <span>*{errorMessage}*</span>
                  </div>
                )}
              </div>

              <div className="flex-self-center rs-form-group">
                <button
                  className="rs-button rs-button-summit"
                  type="button"
                  value="Login"
                  onClick={submitHandler}
                  disabled={
                    details.username === "" ||
                    details.password === "" ||
                    submitted
                  }
                >   
                <div className="flex-row flex-center">
                  <div>Login </div>
                  <div>
                    { submitted && !error && <span style={{ position: "relative", bottom: "-0.9rem" }}>
                      <CustomLoadingIndicator />
                    </span> }
                    </div>
                </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`

.back-logo {
  top: 4rem;
  width: 45rem;
  height: 45rem;
  color: rgba(0,0,0, 0.4);
}
.logo, .welcome, rs-button  {
  margin:auto
}

.logo-img {
  width: 80px;
  height: 80px;
}

.welcome {
  color: grey;
}

input, button, .rs-select {
  appearance: none;
  border: none;
  outline: none;
}

.login-header {
  margin: 0 auto 1.5rem auto;
}

.login-header > h2 {
  color: #888;
  font-size: 28px;
  font-weight: 500;
}

.eye-button{
  position: absolute;
  left: 55px;
}

`;
export default LoginForm;
