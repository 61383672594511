import Axios from "axios";
import { loginRequestDagu, loginRequestMEMIS } from "../Services/loginService";

const baseUrl = "https://dev.webapi.dashboard.hcmisonline.org/api";
const baseUrlDev = "https://dev.webapi.dashboard.hcmisonline.org/api";
const baseUrlProd = "https://dev.webapi.dashboard.hcmisonline.org/api";
const baseUrlKpi = "https://kpi.api.fanosonline.org/api";
const baseUrlKpiDev = "https://kpi.dev.api.fanosonline.org/api";
const baseUrlDagu = "https://dagudashboardbe.k8s.sandboxaddis.com/api";
const baseUrleRIS = "https://api.eris.efda.gov.et/api/Public/Report/Tabular";
const baseUrlMEMIS = "https://api.memis.moh.gov.et/api";

Axios.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let token = userInfo?.Token;
    if (config.url.includes(baseUrlDagu)) {
      const daguToken = localStorage.getItem("daguToken");
      if (!config.url.includes("Login"))
        config.headers["Authorization"] = "Bearer " + daguToken;
    } 
    else if (config.url.includes(baseUrlMEMIS)) {
      const MEMISToken = localStorage.getItem("MEMISToken");
      if (!config.url.includes("login"))
        config.headers["Authorization"] = "Bearer " + MEMISToken;
    } 
    else if (token) {
      config.headers["Authorization"] = "Basic " + token;
    }
    
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      if (error.config.url.includes(baseUrlDagu)) {
        loginRequestDagu().then((daguToken) => {
          const userInfoDagu = localStorage.getItem("daguToken");
          userInfoDagu && localStorage.removeItem("daguToken");
          localStorage.setItem("daguToken", daguToken);
          return Axios(error.config);
        });
      } 
      if (error.config.url.includes(baseUrlMEMIS)) {
        loginRequestMEMIS().then((MEMISToken) => {
          const userInfoMEMIS = localStorage.getItem("MEMISToken");
          userInfoMEMIS && localStorage.removeItem("MEMISToken");
          localStorage.setItem("MEMISToken", MEMISToken);
          return Axios(error.config);
        });
      }
    }
    return Promise.reject(error);
  }
);

const formData = new FormData();
formData.append("length", -1);
formData.append("draw", 1);
formData.append("filters[0][type]", "Integer");
formData.append("filters[0][fieldName]", "product_type");
formData.append("filters[0][isInnerFilter]", true);
formData.append("filters[0][alias]", "smt");
formData.append("filters[0][overridingFieldName]", "id");
formData.append("filters[0][parameterName]", "productType");
formData.append("filters[1][type]", "Integer");
formData.append("filters[1][fieldName]", "application_type");
formData.append("filters[1][isInnerFilter]", true);
formData.append("filters[1][alias]", "ma");
formData.append("filters[1][overridingFieldName]", "is_sra");
formData.append("filters[1][parameterName]", "applicationType");

export const importedAmountFormData = () => {
 return formData;
}

function paramsToFormData(params) {
  var formData = new FormData();

  Object.entries(params).forEach(([key, value]) => {
    if (params[key]) {
      formData.append(key, value);
    }
  });

  return formData;
}

function getBaseUrl(dataSourceCode) {
  var selectedUrl;
  switch (dataSourceCode) {
    case "fanos":
      selectedUrl = baseUrlProd;
      break;
    case "kpi":
      selectedUrl = baseUrlKpiDev;
      break;
    case "dagu":
      selectedUrl = baseUrlDagu;
      break;
    case "eRIS":
      selectedUrl = baseUrleRIS;
      break;
    case "MEMIS":
        selectedUrl = baseUrlMEMIS;
        break;
    default:
      selectedUrl = baseUrl;
      break;
  }

  return baseUrl;
}

export const fetchHubsList = async () => {
  const response = await Axios({
    method: "get",
    url: baseUrl + "/EN_WebApi/ByEnvironmentCode",
    params: {
      EnvironmentGroupCode: "hub",
    },
  });
  return response;
};

export const getSummary = async () => {
  const response = await Axios({
    method: "get",
    url: baseUrl + "/EHPMD_WebApi/SOHByCommodityType",
  });
  return response;
};

export const getNationalRegisteredProducts = async () => {
  const response = await Axios({
    method: "get",
    url: baseUrl + "/EHPMD_WebApi/Eris_RegisteredProductsCount",
  });
  return response;
};

export const getImportedAmount = async () => {
  const response = await Axios.post(baseUrleRIS + "/104", formData);
  return response;
};

export const getFanosData = async (
  api,
  params,
  dataSource,
  method,
  convertParamsToFormData,
  apiData
) => {
  var currrentBaseUrl = "";
  switch (dataSource) {
    case "fanos":
      currrentBaseUrl = baseUrlProd;
      break;
    case "kpi":
      currrentBaseUrl = baseUrlKpiDev;
      break;
    case "dagu":
      currrentBaseUrl = baseUrlDagu;
      break;
    case "eRIS":
      currrentBaseUrl = baseUrleRIS;
      break;
    case "directUrl":
      currrentBaseUrl = '';
      break;
    case "MEMIS":
      currrentBaseUrl = baseUrlMEMIS;
      break;
    default:
      currrentBaseUrl = baseUrl;
      break;
  }

  if (convertParamsToFormData) {
    if (dataSource === "eRIS") {
      formData.append("filters[0][value]", []);
      const response = await Axios.post(baseUrleRIS + api, formData);
      return response;
    } else {
      return await Axios({
        method: method ? method : "post",
        url: currrentBaseUrl + api,
        data: paramsToFormData(params),
        headers: { "Content-Type": "multipart/form-data" }
      });
    }
  } else {
    if(apiData) method = "post";
    return await Axios({
      method: method ? method : "get",
      url: currrentBaseUrl + api,
      params: params,
      data: apiData
    });
  }
};

export const saveRecord = async (
  api,
  data,
  preSaveDataProcess,
  dataSourceCode,
  method
) => {
  var baseUrl = getBaseUrl(dataSourceCode);
  if(preSaveDataProcess) preSaveDataProcess(data);
  return await Axios({
    method: method ? method : "post",
    url: baseUrl + api,
    data: data
  });
};

export const getRegisteredProducts = async () => {
  const response = await Axios({
    method: "get",
    url: baseUrl + "/EHPMD_WebApi/Eris_PercentageOfRegisteredProducts",
  });
  return response;
};

export const getRegisteredProductsEris = async (type) => {
  const response = await Axios.post(baseUrleRIS + type, formData);
  return response;
};

export const getRegisteredProductsRawDataEris = async (
  type,
  length,
  draw,
  searchParam,
  productType
) => {
  const formData = new FormData();
  formData.append("length", length);
  formData.append("draw", draw);
  formData.append("filters[0][isInnerFilter]", true);
  formData.append("filters[0][value]", searchParam);
  formData.append("filters[0][fieldName]", "item");
  formData.append("filters[0][overridingFieldName]", "item");
  formData.append("filters[0][parameterName]", "item");
  formData.append("filters[0][type]", "String");
  formData.append("filters[1][isInnerFilter]", true);
  formData.append("filters[1][value]", productType);
  formData.append("filters[1][fieldName]", "submodule_type_code");
  formData.append("filters[1][overridingFieldName]", "submodule_type_code");
  formData.append("filters[1][parameterName]", "submodule_type_code");
  formData.append("filters[1][type]", "String");

  const response = await Axios.post(baseUrleRIS + type, formData);
  return response;
};
export const getImportedProductsProportion = async (type) => {
  const response = await Axios.post(baseUrleRIS + type, formData);
  return response;
};
export const getLicencedFacilitiesEris = async () => {
  const response = await Axios.get(
    baseUrl + "/eRIS_WebApi/FacilitiesWithCertificates"
  );
  return response;
};
export const getLicencedFacilities = async () => {
  const response = await Axios.get(
    baseUrl + "/eRIS_WebApi/FacilitiesWithCertificates"
  );
  return response;
};
export const getRegisteredProcessTimeEris = async (
  type,
  productType,
  paceOption
) => {
  formData.delete("filters[0][value]");
  formData.delete("filters[1][value]");
  productType === null || productType === 0
    ? formData.append("filters[0][value]", [])
    : formData.append("filters[0][value]", productType);
  paceOption === false
    ? formData.append("filters[1][value]", [])
    : formData.append("filters[1][value]", paceOption);
  const response = await Axios.post(baseUrleRIS + type, formData);
  return response;
};

export const getLiscencedProducts = async () => {
  const response = await Axios({
    method: "get",
    url: baseUrl + "/EHPMD_WebApi/Eris_PermittedProductsCount",
  });
  return response;
};

const requests = [
  fetchHubsList,
  getSummary,
  getNationalRegisteredProducts,
  getImportedAmount,
  getRegisteredProductsEris,
  getRegisteredProductsRawDataEris,
  getImportedProductsProportion,
  getLicencedFacilitiesEris,
  getLicencedFacilities,
  getRegisteredProcessTimeEris,
  getRegisteredProducts,
  getLiscencedProducts,
  getImportedAmount,
  getFanosData,
];

export default requests;
