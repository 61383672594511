export function GaugeNeedle(id, value, chartData, needleConfig) {
  const textYOffset =
    needleConfig.textYOffset !== undefined ? needleConfig.textYOffset : 20;
  const unit = needleConfig.unit !== undefined ? needleConfig.unit : "%";
  const text = needleConfig.text !== undefined ? needleConfig.text + ": " : "";

  return {
    id: id,
    afterDatasetDraw(chart, args, options) {
      const {
        ctx,
        config,
        chartArea: { top, bottom, left, right, width, height },
      } = chart;
      ctx.save();

      const needleValue = value;
      const dataTotal = chartData.datasets[0].data.reduce((a, b) => a + b, 0);
      const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;

      const cx = width / 2;
      const cy = chart._metasets[0].data[0].y;

      //needle
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      if (needleConfig.dashed) {
        ctx.setLineDash([10, 7]);
      }
      ctx.moveTo(0, -2);
      ctx.lineTo(chart._metasets[0].data[0].outerRadius, 0);
      ctx.fillStyle = "#444";
      if (!needleConfig.dashed) {
        ctx.lineTo(0, 2);
        ctx.fill();
      } else ctx.stroke();
      //needle dot
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, 10);
      ctx.fill();
      ctx.restore();
      // text
      ctx.font = "400 18px Rubik";
      ctx.fillStyle = "#444";
      ctx.fillText(text + needleValue + " " + unit, cx, cy + textYOffset);
      ctx.textAlign = "center";
      ctx.restore();
    },
  };
}

export default GaugeNeedle;
