export function DefaultChartDataFormatter(data, config) {
  let dataset = { };

  const dataValue = [];
  const dataLabels = [];

  data &&
    data.map((element) => {
      element && dataValue.push(element[config.dataColumn]);
      element && dataLabels.push(element[config.labelColumn]);
    });

  dataset.dataValue = dataValue;
  dataset.dataLabels = dataLabels;

  return dataset;
}

const ChartDataHelper = []

export default ChartDataHelper;