import React from "react";
import Login from "../login/loginForm";
import Carousel from "../carousel/carousel";
import LandingInfo from "./LandingInfo";
import styled from "styled-components";
import img from "../../Assets/Images/background.jpg";

export default function LandingPage() {
  return (
    <Wrapper> 
      <div className="flex-column flex-center wrapper-container">  
        <div>
          <div className="flex-row flex-item-center flex-wrap rs-landing-">
            <div className="flex-stretch left-side">
              <Login />
            </div>
            <div className="flex-stretch">
              <div className="right-side">
                <Carousel className="carousel" />
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <div className="flex-row flex-wrap mainContainer">
            <div className="left-side">
              <Login />
            </div>
            <div className="right-side">
              <Carousel className="carousel" />
            </div>
          </div> */}
        </div>
        <div style={{margin: "1rem 0"}}>
          <LandingInfo />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;

  .carousel {
    margin: 4rem;
  }
  .rs-landing- {
    max-width: 1300px;
    margin: auto;
    background: white;
  }
  .mainContainer {
    width: 78%;
    height: fit-content;
    max-width: 1300px;
    margin: 2rem 7rem 2rem 7rem;
    padding: 0.5rem;
    background: white;
    border-radius: 6px;
  }
  .right-side {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .left-side {
    min-width: 20rem;
    flex: 1;
    border-right: 1px solid darkgray;
  }
  @media screen and (max-width: 1025px) {
    .mainContainer {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      flex-grow: 1;
    }
    .left-side {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .right-side {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
  @media (min-width: 1120px), (min-height: 630px) {
    body {
      background-size: auto;
    }
  }
  @media screen and (max-width: 750px) {
    background-image: none;
  }
`;
