import React, { useEffect, useState, useImperativeHandle } from "react";
import styled from "styled-components";

import { Formatter, FilterNameFormatter1 } from "../Helpers/FormatHelper.js";
import "./Css/CustomWidget.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { FaExpandAlt } from "react-icons/fa";
import { getFanosData } from "../../Services/dashboardService.js";
import CustomDataGrid from "./CustomDataGrid.js";
import CustomLoadingIndicator from "./Plugins/CustomLoadingIndicator.js";
import nearExpiryIcon from "../../Assets/Images/Icons/near_expiry.png";
import WidgetInfo from "./Plugins/WidgetInfo.js";

function CustomCard2({ widgetConfig, component_config_detail }, ref) {
  const [widgetData, setWidgetData] = useState({});
  const [isLoading, setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    function fetchPageData() {
      getFanosData(
        widgetConfig.data_source_api,
        getUsedFilters(widgetConfig.default_filter_params),
        widgetConfig.source,
        widgetConfig.method
      ).then((data) => {
        setWidgetData(data.data.Data[0]);
        setLoading(false);
      });
    }
    fetchPageData();
  }, []);

  useImperativeHandle(ref, () => ({
    reload(filterParamsState) {
      setLoading(true);
      getFanosData(
        widgetConfig.data_source_api,
        getUsedFilters(filterParamsState),
        widgetConfig.source,
        widgetConfig.method
      ).then((data) => {
        setWidgetData(data.data.Data[0]);
        setLoading(false);
      });
    },
  }));

  function getUsedFilters(allFilterParams) {
    let usedFilters = {};
    Object.keys(allFilterParams).forEach((key) => {
      if (widgetConfig.used_filters_list.includes(key)) {
        usedFilters[FilterNameFormatter1(key)] = allFilterParams[key];
      }
    });

    return usedFilters;
  }

  if (isLoading) {
    return (
      <div className="custom-widget-container flex-column">
        <div className="custom-widget">
          <div className="widget-header-container flex-row">
            <span className="widget-header grow-1">{widgetConfig.title}</span>
            <CustomLoadingIndicator />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Wrapper className="rs-widget">
        <div>
          <Modal className="rs-dialog"
              open={open} onClose={handleClose}>
            <Box className="rs-d-container">
              <CustomDataGrid
                component_config={component_config_detail}
              />
            </Box>
          </Modal>
        </div>
        
        <div className="rs-w-header">
          <div className="flex-row flex-space-between">
            <div>
              {widgetConfig.title + " "}            
              <WidgetInfo widget_config={{
                title: widgetConfig.title,
                page: widgetConfig.page
              }}/>
            </div>
            <div>
              <FaExpandAlt className="expand-icon-2" onClick={handleOpen} />
            </div>
          </div>
        </div>
        <div className="rs-w-body">
          <div className="flex-column">
            <div>
              <div className="flex-row flex-center">
                <div className="widget-body custom-widget-body-1">
                  <img
                    data-deferred="1"
                    alt=""
                    className="custom-widget-icon"
                    data-atf="false"
                    data-iml="232869"
                    src={nearExpiryIcon}
                  ></img>
                </div>
                <div className="widget-body custom-widget-body-1">
                  <h1 className="fw-semi-bold">
                    {widgetData?.[widgetConfig.percentColumn]?.toFixed(2)}%
                  </h1>
                  <span className="widget-value-sm">
                    {Formatter(widgetData?.[widgetConfig.numeratorColumn])}{" "}
                  </span>
                  <span> in {widgetConfig.unit}</span>
                </div>
              </div>
            </div>
            <div style={{alignSelf: "center"}}>
              <span>
                from Total of <span></span>
              </span>{" "}
              <span className="widget-value-sm">
                {Formatter(widgetData?.[widgetConfig.denominatorColumn])}
              </span>
              <span> {widgetConfig.unit}</span>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``

export default React.forwardRef(CustomCard2);
