import { IsEmpty } from "./FormatHelper";

const facilityPages = [ "Facility", "EPSS" ];

export function ShowMenuItem (page, userInfo) {
    if(userInfo?.Regions?.length > 0) {
        return facilityPages.includes(page);
    }
    return true;
}

export function GetUserRegions(regions, userInfo) {
    let userRegions;
    if(IsEmpty(userInfo.Regions)) {
        userRegions = regions;
        userRegions.push( { id: undefined, name: "Federal" } );
    }
    else {
        userRegions = regions
                .filter(region => { 
            return region.sn == userInfo.Regions[0].SN;
        });
        userRegions.push( { id: userInfo.Regions[0].DaguID, name: "Federal" } );
    }
    return userRegions;
}

const PermissionHelper = [
  
]

export default PermissionHelper;
