import moment from "moment";

export function Formatter (value, digit) {
  if(digit === undefined) digit = 1;
  if (value < 1e3) return value;
  if (value > 1e3 && value < 1e6) return (value / 1e3).toFixed(digit) + "K";
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(digit) + "M";
  if (value >= 1e9) return +(value / 1e9).toFixed(digit) + "B";
}

export function FilterNameFormatter1 (filterName) {
  return filterName.charAt(0).toUpperCase() + filterName.replace("Id", "ID").slice(1);
}

export function DefaultDateFormatter(date) {
  try {
    if(date)
      return moment(date).format("MMM DD, YYYY");
    else
      return "";
  } catch (error) {
    return "";
  }
}

export function DefaultAmountFormatter(amount) {
  try {
    if (amount == null) {
      return "";
    }
    const valueFormatted = Number(amount).toLocaleString();
    return `${valueFormatted} Birr`;
  } catch (error) {
    return "";
  }
}

export function PercentFormatter(amount, digit) {
  if(isNaN(parseInt(amount))) {
    return "";
  }
  digit = digit ? digit : 1;
  return amount?.toFixed(digit) + " %";
}

// returns true for undefined, null, [], ""
export function IsEmpty(value) {
  return (value == null || value.length === 0);
}


const FormatHelper = [
  
]

export default FormatHelper;
