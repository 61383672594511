import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { TbStar, TbTriangleInverted, TbTriangle } from "react-icons/tb";
import "./Css/Kpi.css";
import "./Css/Widget.css";
import { Doughnut, Line } from "react-chartjs-2";
import { getRegisteredProcessTimeEris } from "../../Services/dashboardService";
import { getOffsetLeft } from "@mui/material";
import styled from "styled-components";
import Select, { components } from "react-select";
import { FaExpandAlt } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import WidgetInfo from "./Plugins/WidgetInfo";

export default function CustomKpiChart({ kpiConfig }) {
  const [kpiData, setKpiData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [processTime, setProcessTime] = useState(0);
  const [recievedData, setReceivedData] = useState([]);
  const [recievedLabel, setReceivedLabel] = useState([]);
  const [productType, setProductType] = useState(0);
  const [paceOption, setPaceOption] = useState("false");
  const [hasTable, setHasTable] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [target, setTarget] = useState(180);
  const [dataset, setDataset] = useState([target, target]);
  const dataValue = [];
  const defaultStyle = {
    padding: "1rem",
    position: "relative",
    width: "30vw",
    height: "18rem",
    margin: "auto",
  };
  let sum = 0;
  const dataLabel = [];
  const product_type = [
    { label: "Medicine", value: 1 },
    { label: "Food", value: 2 },
    { label: "Medical Device", value: 3 },
    { label: "All", value: 0 },
  ];
  const pace = [
    { label: "SRA", value: "true" },
    { label: "Regular", value: "false" },
  ];

  useEffect(() => {
    setTarget(productType === 2? 15 : paceOption === "false" ? 180 : 90);
    setDataset([productType === 2? 15 : paceOption === "false" ? 180 : 90, productType === 2? 15 : paceOption === "false" ? 180 : 90]);
    setLoading(true);
    function fetchPageData() {
      getRegisteredProcessTimeEris("/98", productType, paceOption).then(
        (data) => {
          setKpiData(data.data.data);
          setLoading(false);
        }
      );
    }
    fetchPageData();
  }, [productType, paceOption]);

  useEffect(() => {
    for (let entry of kpiData) {
      sum += entry.average_processing_time_without_lag_time_in_days;
    }
    let average = sum / kpiData.length;
    dataValue.push(average.toFixed(1));
    dataLabel.push("average processing time in days");
    setProcessTime(average);
    setReceivedData(dataValue);
    setReceivedLabel(dataLabel);
  }, [kpiData]);

  const getOffTarget = () => {
    let offTarget = 0;

    if (processTime > target) {
      offTarget = processTime - target;
    } else {
      offTarget = target - processTime;
    }
    return offTarget.toFixed(0);
  };

  const getPercentage = () => {
    let percentage = 0;

    if (processTime < target) {
      percentage = (processTime / target) * 100;
    } else {
      percentage = (processTime / target) * 100;
    }
    return percentage.toFixed(0);
  };

  const data = {
    labels: ["acceptable", "excess"],
    datasets: [
      {
        data: dataset,
        needleValue: processTime.toFixed(0),
        backgroundColor: [
          "rgb(153, 213, 148)",
          "rgb(213, 62, 79)",
          "rgb(254, 224, 139)",
        ],
        hoverBackgroundColor: [
          "rgb(153, 213, 148)",
          "rgb(213, 62, 79)",
          "rgb(213, 62, 79)",
        ],
        borderWidth: 2,
        circumference: 180,
        rotation: 270,
        cutout: "75%",
      },
    ],
  };

  const gaugeNeedle = {
    id: "gaugeNeedle",
    afterDatasetDraw(chart, args, options) {
      const {
        ctx,
        config,
        chartArea: { top, bottom, left, right, width, height },
      } = chart;
      ctx.save();

      const needleValue = data.datasets[0].needleValue;
      const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0);
      const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;

      const cx = width / 2;
      const cy = chart._metasets[0].data[0].y;

      //needle
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -2);
      ctx.lineTo(chart._metasets[0].data[0].outerRadius, 0);
      ctx.lineTo(0, 2);
      ctx.fillStyle = "#444";
      ctx.fill();
      //needle dot
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, 10);
      ctx.fill();
      ctx.restore();
      // text
      ctx.font = "18px Helvetica";
      ctx.fillStyle = "#444";
      ctx.fillText(needleValue + " days", cx, cy + 20);
      ctx.textAlign = "center";
      ctx.restore();
    },
  };

  const config = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      <Wrapper>
        <div className="select-option">
          <Select
            isClearable={true}
            onChange={(e) => setProductType(e.value)}
            className="select-2"
            options={product_type}
            placeholder="Product Type"
          />
          <Select
            isClearable={true}
            onChange={(e) => setPaceOption(e.value)}
            className="select-2"
            options={pace}
            isDisabled={productType === 2}
            placeholder="Pace"
          />
        </div>
        {isLoading && (
          <div>
            <div className="kpi-item">
              <div className="kpi-header-container">
                <h6>{kpiConfig.title}</h6>
                <div
                  style={{ color: "#3288bd" }}
                  className="css-at12u2-loadingIndicator"
                  aria-hidden="true"
                >
                  <span className="css-3-Loading-dot"></span>
                  <span className="css-2-Loading-dot"></span>
                  <span className="css-1-Loading-dot"></span>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLoading && (
          <div>
            <div>
              <Modal className="rs-dialog"
                  open={open} onClose={handleClose}>
                <Box className="rs-d-container">
                  <h6>{kpiConfig.title}</h6>
                  <div>
                    <Doughnut
                      style={defaultStyle}
                      options={config}
                      data={data}
                      plugins={[gaugeNeedle]}
                    />
                  </div>
                </Box>
              </Modal>
            </div>
            <div> </div>
            <div className="kpi-item">
              <div className="header">
                <div
                  style={{
                    position: "relative",
                    marginBottom: "0.1rem",
                    color: "#475f7b",
                  }}
                >
                  <div>
                    <h6>
                      {kpiConfig.title + " "}
                      <WidgetInfo widget_config={{
                          title: kpiConfig.title,
                          page: kpiConfig.page
                        }}/>
                    </h6>
                  </div>
                  <div>
                    {!isLoading && (
                      <div>
                        <FaExpandAlt
                          className="expand-icon"
                          onClick={handleOpen}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="kpi-body-container">
                <div className="kpi-body-1">
                  <div className="kpi-body-1-container">
                    <div className="kpi-body-1-1" style={{margin: "-2rem 0.5rem"}}>
                      <Doughnut
                        options={config}
                        data={data}
                        plugins={[gaugeNeedle]}
                      />
                    </div>
                    <div className="kpi-body-1-2">
                      {processTime > target && (
                        <span>
                          <TbTriangle
                            size={20}
                            style={{
                              fill: "#EB1D36",
                              color: "#EB1D36",
                              verticalAlign: "baseline",
                            }}
                          />{" "}
                        </span>
                      )}
                      {processTime < target && (
                        <span>
                          <TbTriangleInverted
                            size={20}
                            style={{
                              fill: "Green",
                              color: "Green",
                              verticalAlign: "baseline",
                            }}
                          />{" "}
                        </span>
                      )}
                      {/* {(getOffTarget() && getOffTarget() > 0) || (
                    <span>
                      <TbTriangleInverted
                        size={20}
                        style={{
                          fill: "red",
                          color: "red",
                          verticalAlign: "baseline",
                        }}
                      />{" "}
                    </span>
                  )} */}
                      <span className="kpi-value">
                        {" "}
                        {getPercentage() && getPercentage()} %
                      </span>
                      <br></br>
                      <span>vs. Target</span>
                    </div>
                  </div>
                </div>
                <div className="kpi-body-2">
                  {/* <Line
                data={state}
                options={{
                  scales: {
                    yAxis: {
                      max: kpiData.target == 100 ? 110 : 100,
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              /> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  .kpi-item {
    min-width: 15rem;
    min-height: 15rem;
    max-width: 40rem;
  }
  .expand-icon {
    transform: scale(1);
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .select-2 {
    margin: 0 0.5rem
  }
`;
