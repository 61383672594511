import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Select from 'react-select'
import { FormControlLabel, Checkbox } from "@mui/material";

// assets
import "../../../shared/Style/Form.css";

// custom components
import CustomLoadingIndicator 
    from "../../../shared/Charts/Plugins/CustomLoadingIndicator";

import { preSaveDataProcessUser } 
    from "../../../shared/Helpers/DataServiceHelper";
 
// service
import {
  getFanosData,
  saveRecord
} from "../../../Services/dashboardService";

function UpdateAccount({ component_config }) {
  const jobTitleListApi = "/EHPMD_WebApi/GetJobTitles";
  const userTypeListApi = "/EHPMD_WebApi/GetUserTypes";
  const regionListApi = "/EHPMD_WebApi/GetRegions";
  const updateUserApi = "/EHPMD_WebApi/UpdateUser";

  const [jobTitles, setJobTitles] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  let user = component_config.data;
  user.ID = user.UserID;
  user.UserTypeIDs = [];
  user.RegionIDs = [];
  user.UserTypes.forEach(userType => {
    userType.value = userType.UserTypeID;
    userType.label = userType.UserType;
  });
  user.Regions.forEach(region => {
    region.value = region.RegionID;
    region.label = region.Name;
  });
  const [details, setDetails] = useState(user);

  useEffect(() => {
    getFanosData(jobTitleListApi, {}).then((data) => {
      data.data.forEach(option => {
        option.label = option.Name;
        option.value = option.JobTitleID;
      });
      setJobTitles(data.data);
    });

    getFanosData(userTypeListApi, {}).then((data) => {
      data.data.forEach(option => {
        option.label = option.Name;
        option.value = option.ID;
      });
      setUserTypes(data.data);
    });

    getFanosData(regionListApi, {}).then((data) => {
      data.data.forEach(option => {
        option.label = option.Name;
        option.value = option.ID;
      });
      setRegions(data.data);
    });
  }, []);

  const submitHandler = (e) => {
    setSubmitted(true);
    setError(false);
    saveRecord(updateUserApi, details, preSaveDataProcessUser)
      .then((data) => {
        setSuccessMessage(true);
        setTimeout(() => {
          setSuccessMessage(false);
        }, 5000);
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 10000);
      });
      
      setSubmitted(false);
  };

  return (
    <Wrapper>
      <div>
        <form>
          <div className="rs-form-2">
            <div className="flex-row flex-wrap flex-center rs-form-container">
              <div className="rs-form-group rs-flex-50">
                <div className="flex-column">
                  <label className="rs-label" htmlFor="firstName">First Name:</label>
                  <input className="rs-input" 
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    onChange={(e) =>
                      setDetails({ ...details, FirstName: e.target.value })
                    }
                    value={details.FirstName}
                  />
                </div>
              </div>
              <div className="rs-form-group rs-flex-50">
                <div className="flex-column">
                <label className="rs-label" htmlFor="lastName">Last Name:</label>
                <input className="rs-input" 
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  onChange={(e) =>
                    setDetails({ ...details, LastName: e.target.value })
                  }
                  value={details.LastName}
                />
              </div>
              </div>
              <div className="rs-form-group rs-flex-50">
                <div className="flex-column">
                  <label className="rs-label" htmlFor="username">UserName:</label>
                  <input className="rs-input" 
                    type="text"
                    name="username"
                    id="username"
                    placeholder="UserName"
                    onChange={(e) =>
                      setDetails({ ...details, UserName: e.target.value })
                    }
                    value={details.UserName}
                  />
                </div>
              </div>
              <div className="rs-form-group rs-flex-50">
                <div className="flex-column">
                  <label className="rs-label" htmlFor="email">Email:</label>
                  <div>
                    <input className="rs-input" 
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      onChange={(e) =>
                        setDetails({ ...details, Email: e.target.value })
                      }
                      value={details.Email}
                    />
                  </div>
                </div>
              </div>
              <div className="rs-form-group rs-flex-50">
                <label className="rs-label" htmlFor="jobtitle">Job Title:</label>
                <div>
                  <Select
                      className="basic-select" 
                      menuPlacement="top"
                      options={jobTitles}
                      defaultValue={{ label: details.JobTitle, value: details.JobTitleID }}
                      onChange={(selected) => 
                          setDetails({...details, JobTitleID : selected.value})
                        }
                    />
                  </div>
              </div>
              <div className="rs-form-group rs-flex-50">
                <label className="rs-label" htmlFor="usertype">User Types:</label>
                <div>
                  <Select isMulti 
                      className="basic-select" 
                      menuPlacement="top"
                      options={userTypes}
                      defaultValue={details.UserTypes}
                      onChange={(selectedOptions) => {
                        setDetails({ ...details, UserTypes: selectedOptions })
                      }
                      }
                  />
                </div>
              </div>
              <div>
                <label className="rs-label" htmlFor="region">Regions:</label>
                <div>
                  <Select isMulti
                      className="basic-select" 
                      menuPlacement="top"
                      options={regions}
                      defaultValue={details.Regions}
                      onChange={(selectedOptions) => {
                        setDetails({ ...details, Regions: selectedOptions })
                      }
                      }
                  />
              </div>
              <div className="rs-form-group rs-flex-50">
                <div className="flex-column">
                  <FormControlLabel
                      control={
                        <Checkbox
                          name="IsActive"
                          
                          placeholder="Is Active"
                          onChange={ (e, value) => {
                            setDetails({ ...details, IsActive: value })
                          }}
                          defaultChecked={ details.IsActive }
                        />
                      }
                      label="Is Active"
                  />
                </div>
              </div>
              {error && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "red",
                    marginTop: "1rem",
                  }}
                >
                  <span>*update failed*</span>
                </div>
              )}

              {successMessage && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "green",
                    marginTop: "1rem",
                  }}
                >
                  <span>success</span>
                </div>
              )}

              </div>
              <div className="rs-form-group rs-flex-50">
                <div className="flex-column">
                  <button
                    className="rs-button rs-button-summit"
                    type="button"
                    value="Login"
                    onClick={submitHandler}
                    disabled={
                      details.username === "" ||
                      details.password === "" ||
                      submitted
                    }
                  >
                    <span>Update </span>
                    { submitted && !error && <span style={{ position: "relative", bottom: "-0.9rem" }}>
                      <CustomLoadingIndicator />
                    </span> }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
export default UpdateAccount;
