export const userListColumns = [
{
    type: "link",
    field: "Edit",
    headerName: "",
    flex: 1,
    minWidth: 50,
},
{
    field: "FullName",
    headerName: "Full Name",
    flex: 2.5,
    minWidth: 200,
},
{
    field: "UserName",
    headerName: "Username",
    flex: 1,
    minWidth: 200,
},
{
    field: "JobTitle",
    headerName: "Job Title",
    flex: 1,
    minWidth: 200,
},
{
    field: "Email",
    headerName: "Email",
    flex: 1,
    minWidth: 200,
}
];

export const EHPMD_JOBTITLES_SELECT_CONFIG = {
    api_url: '/EHPMD_WebApi/GetJobTitles',
    label_property: "Name",
    value_property: "Name",
    place_holder: "Job Title",
    param_name: "",
  };


const ADMIN_CONSTANTS = [ userListColumns ];

export default ADMIN_CONSTANTS;
