import Axios from "axios";

const baseUrl = "https://dev.webapi.dashboard.hcmisonline.org/api";
const baseUrlDagu= "https://dagudashboardbe.k8s.sandboxaddis.com/api";
const baseUrlMEMIS= "https://api.memis.moh.gov.et/api";

export const loginRequest = async (data) => {
  // console.log(data);
  const response = await Axios.post(
    baseUrl + "/EHPMD_WebApi/login",
    null,
    {
      params: {
        password: data.password,
        userName: data.username
      },
    }
  );
  return response.data;
};

export const loginRequestDagu = async () => {
  const response = await Axios({
    method: 'post',
    url: baseUrlDagu + "/Account/Login",
    headers: {}, 
    data: {
      username:'fanosAdmin', password:'Fanos@123'
    }
  });
  return response.data.token;
};

export const loginRequestMEMIS = async () => {
  const response = await Axios({
    method: 'post',
    url: baseUrlMEMIS + "/accounts/login",
    headers: {}, 
    data: {
      Email:'yandamlak@et.jsi.com', Password:'Nd@123'
    }
  });
  return response?.data?.data?.token;
};

const requests = [loginRequest];

export default requests;
