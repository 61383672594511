import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import styled from "styled-components";

import CustomDataGrid from "../../../shared/Charts/CustomDataGrid";
import CustomCard from "../../../shared/Charts/CustomCard";

import SimpleDatePicker from "../../../shared/Inputs/SimpleDatePicker";

import {
  GetMemisStausColumns
} from "../../../shared/Charts/Constants/CustomDataGridConstants";

import {
  PercentFormatter
} from "../../../shared/Helpers/FormatHelper.js";


// data service
import {
  getFanosData,
} from "../../../Services/dashboardService";

// icons
import {
  MdOutlineSettings,
  MdOutlineCheckCircle,
  MdOutlineDownloadForOffline,
  MdApps,
  MdPeopleOutline,
  MdOutlineEventAvailable,
  MdStop
} from "react-icons/md";

import { GoTools } from "react-icons/go";

function MedicalEquipment() {
  const PAGE = "medical_equipment";
  const [pageFilterParams, setPageFilterParams] = useState({ }); 
  const app = useSelector(selectors.app);
  const [equipmentAvailability, setEquipmentAvailability] = useState(null);
  const [functionality, setFunctionality] = useState(null);
  const [preventiveMaintenances, setPreventiveMaintenances] = useState(null);
  const [workorders, setWorkorders] = useState(null);
  const [installations, setInstallations] = useState(null);
  const [memisFunctionality, setmemisFunctionality] = useState(null);
  const [committeeFunctionality, setCommitteeFunctionality] = useState(null);
  const [workshopAvailability, setWorkshopAvailability] = useState(null);

  const [tabs, setTabs] = useState(["Functionality", "Maintenance", "Installation", "MEMIS", "Committee", "Workshop", "Availability", "PreventiveMaintenance"]);

  // apis
  const functionalitiesApi = "/national-dashboard/equipment-functionalities";
  const workordersApi = "/national-dashboard/maintenance-workorders";
  const installationsApi = "/national-dashboard/equipment-installations";
  const memisFunctionalityApi = "/national-dashboard/memis-functionalities";
  const committeeFunctionalityApi = "/national-dashboard/committee-functionalities";
  const workshopAvailabilityApi = "/national-dashboard/workshop-availabilities";
  const availabilityEquipmentApi = "/national-dashboard/equipment-availabilities";
  const preventiveMaintenanceApi = "/national-dashboard/preventive-maintenances";

  const [memisStatusColumns, setMemisStatusColumns] = useState(GetMemisStausColumns("Functionality"));

  useEffect(() => {
    getFanosData(
      availabilityEquipmentApi, pageFilterParams, "MEMIS", "GET", false, 
    ).then((response) => {
      var resp = response.data.data.list.percentage;
      setEquipmentAvailability(resp == null ? 0 : resp);;
    })

    getFanosData(
      functionalitiesApi, pageFilterParams, "MEMIS", "GET", false, 
    ).then((response) => {
      setFunctionality(response.data.data.list.percentage);
    })

    getFanosData(
      preventiveMaintenanceApi, pageFilterParams, "MEMIS", "GET", false, 
    ).then((response) => {
      var resp = response.data.data.list.percentage;
      setPreventiveMaintenances(resp == null ? 0 : resp);
    })

    getFanosData(
      workordersApi, pageFilterParams, "MEMIS", "GET", false, 
    ).then((response) => {
      setWorkorders(response.data.data.list.percentage);
    })

    getFanosData(
      installationsApi, pageFilterParams, "MEMIS", "GET", false, 
    ).then((response) => {
      setInstallations(response.data.data.list.percentage);
    })

    getFanosData(
      memisFunctionalityApi, pageFilterParams, "MEMIS", "GET", false, 
    ).then((response) => {
      var resp = response.data.data.list.percentage;
      setmemisFunctionality(resp == null ? 0 : resp);
    })

    getFanosData(
      committeeFunctionalityApi, pageFilterParams, "MEMIS", "GET", false, 
    ).then((response) => {
      var resp = response.data.data.list.percentage;
      setCommitteeFunctionality(resp == null ? 0 : resp);
    })

    getFanosData(
      workshopAvailabilityApi, pageFilterParams, "MEMIS", "GET", false, 
    ).then((response) => {
      var resp = response.data.data.list.percentage;
      setWorkshopAvailability(resp == null ? 0 : resp);
    })
  }, [pageFilterParams]);

  return (
    <Wrapper>
    <div className="container-context">
      <div className="main-body">
        <div className="main-body-container">
          <div className="section-header">
            <div className="section-header-container">
              <p className="section-header-title">Medical Equipment</p>
            </div>
            
            <div className="widget-toolbar">  
              <div className="widget-toolbar-container">
                <SimpleDatePicker 
                  component_config={{
                    start_date_param_name: "StartDate",
                    end_date_param_name: "EndDate"
                  }} 
                  handle_change={(a) => (setPageFilterParams(a))}
                />
              </div>
            </div>
          </div>
          <div className="flex-row flex-wrap">
            <CustomCard
              widget_config={{                  
                page: PAGE,
                title: "Functionality Rate",
                description: "Percentage of medical equipment functionality",
                value: PercentFormatter(functionality),
                type: "",
                accent_color: "#0e74b8"
              }}
              Icon={MdOutlineCheckCircle}
            />
            <CustomCard
              widget_config={{                  
                page: PAGE,
                title: "Maintenance Work Order Rate",
                description: "Percentage of medical equipment maintenance work order performed",
                value: PercentFormatter(workorders),
                type: "",
                accent_color: "#0e74b8"
              }}
              Icon={MdOutlineSettings}
            />
            <CustomCard
              widget_config={{                  
                page: PAGE,
                title: "Installation Rate",
                description: "Percentage of medical equipment installation",
                value: PercentFormatter(installations),
                type: "",
                accent_color: "#0e74b8"
              }}
              Icon={MdOutlineDownloadForOffline}
            />
            <CustomCard
              widget_config={{                  
                page: PAGE,
                title: "MEMIS Functionality",
                description: "Percentage of facilities with functional MEMIS",
                value: PercentFormatter(memisFunctionality),
                type: "",
                accent_color: "#0e74b8"
              }}
              Icon={MdApps}
            />
            <CustomCard
              widget_config={{                  
                page: PAGE,
                title: "Committee Functionality",
                description: "Percentage of Committee Functionality",
                value: PercentFormatter(committeeFunctionality),
                type: "",
                accent_color: "#0e74b8"
              }}
              Icon={MdPeopleOutline}
            />
            <CustomCard
              widget_config={{                  
                page: PAGE,
                title: "Workshop Availability",
                description: "Workshop Availability",
                value: PercentFormatter(workshopAvailability),
                type: "",
                accent_color: "#0e74b8"
              }}
              Icon={GoTools}
            />           
            <CustomCard
              widget_config={{                  
                page: PAGE,
                title: "Equipment Availability",
                description: "Availability of medical equipment as per the national standard",
                value: PercentFormatter(equipmentAvailability),
                type: "",
                accent_color: "#0e74b8"
              }}
              Icon={MdOutlineEventAvailable}
            />
            <CustomCard
              widget_config={{                  
                page: PAGE,
                title: "Preventive Maintenance",
                description: "Preventive maintenance practice",
                value: PercentFormatter(preventiveMaintenances),
                type: "",
                accent_color: "#0e74b8"
              }}
              Icon={MdStop}
            />
          </div>
          <div className="flex-column section-main-container">
            <div className="section-header">
              <div className="section-body">
                  <div className="section-widgets-container section-widgets-container-data-grid">
                  <CustomDataGrid
                    component_config={{
                      page: PAGE,
                      title: "Medical Device Status",
                      data_source_api: functionalitiesApi,
                      //data_manipulator: MedicalDeviceStatusProcess,
                      data_source: "MEMIS",
                      data_property: "list",
                      columns: memisStatusColumns,
                      defaultFilterParams: { isDetail: true },
                      select_filter_config: [ ],
                      legend: null,
                      tabs: tabs,
                      active_tab: "Functionality",
                      tab_apis: {
                        Functionality: functionalitiesApi, 
                        Maintenance: workordersApi, 
                        Installation: installationsApi, 
                        MEMIS: memisFunctionalityApi,
                        Committee: committeeFunctionalityApi,
                        Workshop: workshopAvailabilityApi,
                        Availability: availabilityEquipmentApi,
                        PreventiveMaintenance: preventiveMaintenanceApi
                      },
                      getColumnsFunction: GetMemisStausColumns,
                      setColumns: setMemisStatusColumns
                    }}
                  />
                  </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  
  @media screen and (max-width: 1025px) {
    .container-body {
    display: flex;
    flex-direction: column;
  }
`;
export default MedicalEquipment;
