import React, {  } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import styled from "styled-components";
import CustomDataGrid from "../../../shared/Charts/CustomDataGrid";

import { licencedFacilityColumns } from "../../../shared/Charts/Constants/CustomDataGridConstants";
import { FACILITY_CODE_SELECT_CONFIG } from "../../../shared/Inputs/SelectConstants";

export default function LicencedFacilities() {
  const app = useSelector(selectors.app);
  const licendFacilitesApi = "/eRIS_WebApi/FacilitiesWithCertificates";

  return (
    <Wrapper>
      
      <div className="container-context">
        <div className="main-body">
          <div className="main-body-container">
            <CustomDataGrid
              component_config={{
                title: "Licenced Facilities Count",
                columns: licencedFacilityColumns,
                data_source_api: licendFacilitesApi,
                data_source: "fanos",
                convert_params_to_formData: false,
                data_property: "Data",
                method: "GET",
                select_filter_config: [FACILITY_CODE_SELECT_CONFIG],
                defaultFilterParams: {},
                legend: null,
              }}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 1025px) {
    .container-body {
      display: flex;
      flex-direction: column;
    }
  }
`;

const cardSubtitle = {
  margin: "1rem",
  fontSize: "14px",
  color: "#475f7b",
};
