import React, {
  useState,
  useEffect
} from "react";
import styled from "styled-components";

// data service
import {
  getFanosData
} from "../../Services/dashboardService";

// custom components
import WidgetInfo from "./Plugins/WidgetInfo";
import CustomLoadingIndicator 
    from "./Plugins/CustomLoadingIndicator";
// Icons
import { MdCheckCircleOutline, 
    MdOutlinePlayCircleOutline,
    MdArrowForward } from "react-icons/md";

function WorkflowCard({ widget_config, Icon }) {
  const [widgetData, setWidgetData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
		getFanosData(widget_config.data_source_api)
				.then((data) => {
      setWidgetData( data.data.Data );
      setLoading(false);
		});
	}, []);

  return (
    <>
      <Wrapper className="rs-widget-workflow rs-widget-basic">
        <div className="flex-column">
          <div className="rs-w-header">
            <div className="flex-row flex-space-between">
              <div className="flex-row">
                {widget_config.title + " "}
                <WidgetInfo widget_config={{
                  title: widget_config.title,
                  page: widget_config.page
                }}/>
                { !isLoading || <CustomLoadingIndicator component_config={{}} /> }
              </div>
            </div>
            <div className="bottom-line">
              <div className="decorative-bar medium bg-blue-p"></div>
            </div>
          </div>
          <div className="rs-w-body workflow" style={{ opacity: isLoading ? "0.5" : "1" }}>
            { widgetData.length > 2 && <div className="flex-row flex-item-center" style={{ marginTop: "0.5rem"}}>
              <div className="flex-column flex-item-center workflow-step-content">
                <MdOutlinePlayCircleOutline size={25} />
                <div>{widget_config.startProcess}</div>
              </div>
              { widgetData.map(function (step, index) {
                return (
                  <div className="flex-row flex-item-center">
                    <div className={ index % 2 == 0 
                        ? "workflow-step-even"
                        : "workflow-step-odd"}>
                      <div className="workflow-step">
                      <div className="flex-column flex-item-center workflow-step-content">
                        
                        {widget_config.icons[index]}
                        { <h2> { step.AverageLeadTime > 0 ? step.AverageLeadTime : "--" }</h2> }
                        <div className="step-label"> { step.ProcessName } </div>
                      </div>
                      </div>
                    </div>
                    <div><MdArrowForward  color="#21618c" size={25} /></div>
                  </div>
                );
              })}
              <div className="flex-column flex-center flex-item-center workflow-step-content">
                <MdCheckCircleOutline size={25} />
                <div>{widget_config.endProcess}</div>
              </div>
            </div> }
          </div>
        </div>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.div`
min-width: 23rem;
min-height: 17rem;

.workflow {
  overflow-x: scroll;
  min-height: 12rem;
}
.workflow-step-even {
  border-top: 1px solid #21618c;
  padding: 0.25rem;
  margin: -0.25rem;
  border-radius: 15rem;
}
.workflow-step-odd {
  border-bottom: 1px solid #21618c;
  padding: 0.25rem;
  margin: -0.25rem;
  border-radius: 15rem;
}
.workflow-step {
  border: 1px solid #21618c;
  border-radius: 15rem;
  width: 9rem;
  height: 9rem;
  min-width: 9rem;
  min-height: 9rem;
  margin: 0.25rem;
}
.workflow-step-content {
  padding: 0.5rem;
}
.step-label {
  text-align: center;
  font-size: 0.8rem;
  width: 80%;
  height: 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
}
`
export default WorkflowCard;
