import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { selectors } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../app.state";
import { MdDashboard } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { TbJumpRope } from "react-icons/tb";
import { IoIosLogOut } from "react-icons/io";
import { MdOutlineMedicalServices } from "react-icons/md";
import { MdOutlineLocalPharmacy } from "react-icons/md";
import { TbBuildingFactory } from "react-icons/tb";
import { GiMedicines } from "react-icons/gi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { TbMedicineSyrup } from "react-icons/tb";
import styled from "styled-components";

import "./style.css";
import { useState } from "react";

import { ShowMenuItem } from "../Helpers/PermissionHelper";

function SideNav() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const locationPath = useLocation();

  const history = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  const app = useSelector(selectors.app);

  const handleNavigation = (e) => {
    if (e === "SupplyChain" || e === "SupplyChainFacility") {
      dispatch(appActions.setNav(e));
      history(`/${e}`);
    } else {
      const name = e.currentTarget.innerText.replace(/\s+/g, "");
      dispatch(appActions.setNav(name));
      history(`/${name}`);
    }
  };

  return (
    <Wrapper>
      { userInfo?.Username && locationPath?.pathname != "/login" &&
      <div className="sidebar">
          <div className="sd-body">
            <ul>
              <li
                className={
                  app.setNav === "Dashboard" ? "sd-link-selected" : "sd-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <MdDashboard className="icon" />
                Dashboard
              </li>
              { ShowMenuItem("Regulatory", userInfo) && <li
                className={
                  app.setNav === "Regulatory" ? "sd-link-selected" : "sd-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <GiMedicines className="icon" />
                <br />Regulatory
              </li> }
              <li
                className="sd-link"
                onClick={() =>
                  dispatch(appActions.setSupplyChainNav(!app.setSupplyChainNav))
                }
              >
                <TbJumpRope className="icon" /> Supply Chain
              </li>
              {app.setSupplyChainNav && (
                <div className="subMenu">
                  { ShowMenuItem("EPSS", userInfo) && <li
                    className={
                      app.setNav === "SupplyChain"
                        ? "sd-link-selected"
                        : "sd-link"
                    }
                    onClick={(e) => handleNavigation("SupplyChain")}
                  >
                    <TbMedicineSyrup className="icon" />
                    <br />EPSS
                  </li> }
                  { ShowMenuItem("Facility", userInfo) && <li
                    className={
                      app.setNav === "SupplyChainFacility"
                        ? "sd-link-selected"
                        : "sd-link"
                    }
                    onClick={(e) => handleNavigation("SupplyChainFacility")}
                  >
                    <HiOutlineOfficeBuilding className="icon" /> Facility
                  </li> }
                </div>
              )}

              { ShowMenuItem("Regulatory", userInfo) && <li
                className={
                  app.setNav === "MedicalEquipment"
                    ? "sd-link-selected"
                    : "sd-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <MdOutlineMedicalServices className="icon" /> Medical Equipment
              </li> }
              { ShowMenuItem("Regulatory", userInfo) && <li
                className={
                  app.setNav === "PharmacyService"
                    ? "sd-link-selected"
                    : "sd-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <MdOutlineLocalPharmacy className="icon" /> Pharmacy Service
              </li> }
              { ShowMenuItem("Regulatory", userInfo) && <li
                className={
                  app.setNav === "LocalManufacturer"
                    ? "sd-link-selected"
                    : "sd-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <TbBuildingFactory className="icon" /> Local Manufacturer
              </li> }
            </ul>
          </div>
        </div>
    }
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  .subMenu {
    margin-left: 1rem;
  }

  .icon {
    display: inline-block !important;
    margin-right: 7px;
    height: 18px;
    font-size: 18px;
  }
  .menu-icon {
    display: block;
    font-size: 20px;
  }
`;
export default SideNav;
