import "./App.css";
import { useEffect } from "react";

import ReactGA from 'react-ga4';

import Dashboard from "./components/Dashboard/Dashboard";
import LandingPage from "./components/LandingPage/LandingPage";
import Admin from "./components/Pages/admin/admin";
import MedicalEquipment from "./components/Pages/medicalEquipment/medicalEquipment";
import PharmacyService from "./components/Pages/pharmacy service/pharmacyService";
import SupplyChain from "./components/Pages/supplyChain/supplyChain";
import SupplyChainFacility from "./components/Pages/supplyChain/supplyChainFacility";
import LocalManufacturer from "./components/Pages/local manufacturer/localManufacturer";
import EFDA from "./components/Pages/EFDA/EFDA";
import LicencedFacilities from "./components/Pages/EFDA/licensedProducts";
import ImportedProducts from "./components/Pages/EFDA/importedProducts";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import RegisteredProducts from "./components/Pages/EFDA/registeredProducts";


import Navbar from "./shared/Navigation/Navbar";
import SideNav from "./shared/Navigation/SideNav";

function App() {
  const history = useNavigate();
  const locationPath = useLocation();

  const RedierectToLogin = () => {
    history("/Dashboard");
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: locationPath.pathname + locationPath.search });
  }, [locationPath]);

  useEffect(() => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;

    const authData = localStorage.getItem("userInfo");
    if (!authData) {
      history("/login");
    } else {
      const userInfo = JSON.parse(authData);
      const tokenExpiryTime = userInfo?.ExpiryTime;
      if (new Date(dateTime).getTime() >= new Date(tokenExpiryTime).getTime()) {
        history("/login");
      } else {
        history(`${locationPath.pathname}`);
        // history("/Dashboard");
      }
    }

    // if (parseInt(dateTime) > parseInt(tokenExpiryTime))
  }, [ ]);
  return (
    <div className="flex-column">
      <div>
        <Navbar />
      </div>
      <div>
        <div>
          <SideNav />
        </div>
        <Routes>
          <Route path="/" element={<RedierectToLogin />}></Route>
          <Route path="/Dashboard" element={<Dashboard />}></Route>
          <Route path="/login" element={<LandingPage />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/MedicalEquipment" element={<MedicalEquipment />}></Route>
          <Route path="/PharmacyService" element={<PharmacyService />}></Route>
          <Route 
            path="/SupplyChain/:section_id?" 
            element={<SupplyChain />}></Route>
          <Route
            path="/SupplyChainFacility/:section_id?"
            element={<SupplyChainFacility />}
          ></Route>
          <Route
            path="/LocalManufacturer"
            element={<LocalManufacturer />}
          ></Route>
          <Route path="/Regulatory" element={<EFDA />}></Route>
          <Route
            path="/Regulatory/LicencedFacilities"
            element={<LicencedFacilities />}
          ></Route>
          <Route
            path="/Regulatory/RegisteredProducts"
            element={<RegisteredProducts />}
          ></Route>
          <Route
            path="/Regulatory/ImportedProducts"
            element={<ImportedProducts />}
          ></Route>
        </Routes>
      </div>
      
      <Routes>
          
      </Routes>
    </div>
  );
}

export default App;
