import React from "react";
import { useNavigate } from "react-router-dom";
import { selectors } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../app.state";
import { MdDashboard } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { TbJumpRope } from "react-icons/tb";
import { IoIosLogOut } from "react-icons/io";
import { MdOutlineMedicalServices } from "react-icons/md";
import { MdOutlineLocalPharmacy } from "react-icons/md";
import { TbBuildingFactory } from "react-icons/tb";
import { GiMedicines } from "react-icons/gi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { TbMedicineSyrup } from "react-icons/tb";
import styled from "styled-components";

import "./style.css";
import { useState } from "react";

import { ShowMenuItem } from "../Helpers/PermissionHelper";

function SideNav() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const history = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  const app = useSelector(selectors.app);

  const ToggleSidebar = () => {
    isOpen === true ? setIsOpen(false) : setIsOpen(true);
    dispatch(appActions.collapseSideNav(!app.collapseSideNav));
  };

  const handleNavigation = (e) => {
    if (e === "SupplyChain" || e === "SupplyChainFacility") {
      dispatch(appActions.setNav(e));
      history(`/${e}`);
    } else {
      const name = e.currentTarget.innerText.replace(/\s+/g, "");
      dispatch(appActions.setNav(name));
      history(`/${name}`);
    }
  };

  return (
    <Wrapper>
      {isOpen && (
        <div>
          <GiHamburgerMenu className="nav-menu-icon" onClick={ToggleSidebar} />
        </div>
      )}
      {!isOpen && (
        <div className="sidebar-new">
          <div className="sdn-body">
            <ul>
              <li
                className={
                  app.setNav === "Dashboard" ? "sdn-link-selected" : "sdn-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <MdDashboard className="icon" />
                Dashboard
              </li>
              { ShowMenuItem("Regulatory", userInfo) && <li
                className={
                  app.setNav === "Regulatory" ? "sdn-link-selected" : "sdn-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <GiMedicines className="icon" />
                Regulatory
              </li> }
              <li
                className="sdn-link"
                onClick={() =>
                  dispatch(appActions.setSupplyChainNav(!app.setSupplyChainNav))
                }
              >
                <TbJumpRope className="icon" /> Supply Chain
              </li>
              {app.setSupplyChainNav && (
                <div className="subMenu">
                  { ShowMenuItem("EPSS", userInfo) && <li
                    className={
                      app.setNav === "SupplyChain"
                        ? "sdn-link-selected"
                        : "sdn-link"
                    }
                    onClick={(e) => handleNavigation("SupplyChain")}
                  >
                    <TbMedicineSyrup className="icon" />
                    EPSS
                  </li> }
                  { ShowMenuItem("Facility", userInfo) && <li
                    className={
                      app.setNav === "SupplyChainFacility"
                        ? "sdn-link-selected"
                        : "sdn-link"
                    }
                    onClick={(e) => handleNavigation("SupplyChainFacility")}
                  >
                    <HiOutlineOfficeBuilding className="icon" /> Facility
                  </li> }
                </div>
              )}

              { ShowMenuItem("Regulatory", userInfo) && <li
                className={
                  app.setNav === "MedicalEquipment"
                    ? "sdn-link-selected"
                    : "sdn-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <MdOutlineMedicalServices className="icon" /> Medical Equipment
              </li> }
              { ShowMenuItem("Regulatory", userInfo) && <li
                className={
                  app.setNav === "PharmacyService"
                    ? "sdn-link-selected"
                    : "sdn-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <MdOutlineLocalPharmacy className="icon" /> Pharmacy Service
              </li> }
              { ShowMenuItem("Regulatory", userInfo) && <li
                className={
                  app.setNav === "LocalManufacturer"
                    ? "sdn-link-selected"
                    : "sdn-link"
                }
                onClick={(e) => handleNavigation(e)}
              >
                <TbBuildingFactory className="icon" /> Local Manufacturer
              </li> }
            </ul>
          </div>
        </div>
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  .subMenu {
    margin-left: 1rem;
  }

  .icon {
    display: inline-block !important;
    margin-right: 7px;
    height: 2rem;
    font-size: 1.5rem;
  }
  .menu-icon {
    display: block;
    font-size: 20px;
  }
  .sdn-header {
    display: none;
  }
  
  .sdn-body{
    padding: 0px 7px 0px 0px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
    margin: 1em 3px 3px 3px;
  }
  .sdn-body ul{
    
    width: 100%;
    margin-bottom: 0;
    padding: 0;
    font-size: 13px;
  }
  .sdn-body ul li{
    list-style: none;
    margin-bottom: 1rem;
  }
  .sdn-body ul li {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }

  .sidebar-new{
  }

  .sdn-link{
    display: inline-block;
    width: 100%;
    padding: 7px 13px;
    /* border-radius: 4px; */
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0px;
  }

  .sdn-link:hover{
    transform: scale(1,1);
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    background-color: white;
    color: #21618c;
    border-radius: 4px;
  }
  .sdn-link-selected{
    display: inline-block;
    color: #21618c;
    width: 100%;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0px;
    background-color: white;
  }

  @media screen and (max-width: 1025px) {
    .icon {
      display: inline-block !important;
      margin-right: 7px;
      height: 33px;
      font-size: 25px;
    }
    .menu-icon {
      display: block;
      font-size: 20px;
    }
  }
`;
export default SideNav;
