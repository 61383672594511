import React, { useEffect, useState, useImperativeHandle } from "react";
import { Formatter, FilterNameFormatter1 } from "../Helpers/FormatHelper.js";
import "./Css/CustomWidget.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { FaExpandAlt } from "react-icons/fa";
import { getFanosData } from "../../Services/dashboardService";
import CustomDataGrid from "./CustomDataGrid";
import { NEAR_EXPIRY_COLUMNS } from "./Constants/CustomDataGridConstants";
import CustomLoadingIndicator from "./Plugins/CustomLoadingIndicator";
import nearExpiryIcon from "../../Assets/Images/Icons/near_expiry.png";
import WidgetInfo from "./Plugins/WidgetInfo.js";

import {
  FN_COMMODITY_TYPE_ID_SELECT_CONFIG,
  FN_ENVIRONMENT_ID_SELECT_CONFIG,
  FN_MODE_ID_SELECT_CONFIG,
} from "../Inputs/SelectConstants";

import { NearExpiryDM } from "../Helpers/DataManipulationHelper";

function CustomWidget({ widgetConfig }, ref) {
  const [apiData, setApiData] = useState({});
  const [widgetData, setWidgetData] = useState({});
  const [isLoading, setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    function fetchPageData() {
      getFanosData(
        widgetConfig.data_source_api,
        widgetConfig.operationType === "Total"
          ? widgetConfig.default_filter_params
          : getUsedFilters(widgetConfig.default_filter_params),
        widgetConfig.source,
        widgetConfig.method
      ).then((data) => {
        setApiData(data.data.model);
        let res = data.data.model;
        let sum = 0;

        if (widgetConfig.operationType === "Total") {
          res?.map((el) => {
            el && (sum = sum + el.neQuantityTotalCost);
          });
          setWidgetData(sum);
        } else {
          setWidgetData(data.data.Data[0]);
        }
        setLoading(false);
      });
    }
    fetchPageData();
    //setLoading(false);
  }, []);

  useImperativeHandle(ref, () => ({
    reload(filterParamsState) {
      setLoading(true);
      getFanosData(
        widgetConfig.data_source_api,
        widgetConfig.operationType === "Total"
          ? filterParamsState
          : getUsedFilters(filterParamsState),
        widgetConfig.source,
        widgetConfig.method
      ).then((data) => {
        let res = data.data.model;
        let sum = 0;
        if (widgetConfig.operationType === "Total") {
          res?.map((el) => {
            el && (sum = sum + el.neQuantityTotalCost);
          });
          setWidgetData(sum);
        } else {
          setWidgetData(data.data.Data[0]);
        }
        setLoading(false);
      });
    },
  }));

  function getUsedFilters(allFilterParams) {
    let usedFilters = {};
    Object.keys(allFilterParams).forEach((key) => {
      if (widgetConfig.used_filters_list.includes(key)) {
        usedFilters[FilterNameFormatter1(key)] = allFilterParams[key];
      }
    });

    return usedFilters;
  }

  if (isLoading) {
    return (
      <div className="custom-widget-container flex-column">
        <div className="custom-widget">
          <div className="widget-header-container flex-row">
            <span className="widget-header grow-1">{widgetConfig.title}</span>
            <CustomLoadingIndicator />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{flex: 1, minWidth: "23rem", minHeight: "15rem"}}>
        <div>
          <Modal className="rs-dialog"
              open={open} onClose={handleClose}>
            <Box className="rs-d-container">
              <CustomDataGrid
                component_config={{
                  title: widgetConfig.title,
                  columns: widgetConfig.columnDef,
                  data_source_api: widgetConfig.gridApi,
                  data_manipulator:
                    widgetConfig.operationType !== "Total" ? NearExpiryDM : null,
                  data_property: "Data",
                  select_filter_config: widgetConfig.filterConfig,
                  defaultFilterParams: {},
                  density: "compact",
                  method: "post",
                  api_data: { data: apiData }
                }}
              />
            </Box>
          </Modal>
        </div>
        <div className="custom-widget-container flex-column">
          <div className="custom-widget">
            <div className="widget-header-container flex-row">
              <span className="widget-header grow-1">
                {widgetConfig.title + " "}            
                <WidgetInfo widget_config={{
                  title: widgetConfig.title,
                  page: widgetConfig.page
                }}/>
              </span>
              <div>
                <div>
                  <FaExpandAlt className="expand-icon-2" onClick={handleOpen} />
                </div>
              </div>
            </div>
            <div className="widget-body-container flex-column">
              <div className="widget-body">
                <div className="custom-widget-body-1-container flex-row">
                  <div className="widget-body custom-widget-body-1">
                    <img
                      data-deferred="1"
                      alt=""
                      className="custom-widget-icon"
                      data-atf="false"
                      data-iml="232869"
                      src={nearExpiryIcon}
                    ></img>
                  </div>
                  {widgetConfig.operationType !== "Total" && (
                    <div className="widget-body custom-widget-body-1">
                      <h1 className="fw-semi-bold">
                        {widgetData.NearExpiryPercentage.toFixed(2)}%
                      </h1>
                      <span className="widget-value-sm">
                        {Formatter(widgetData.L6MonthAmount)}{" "}
                      </span>
                      <span> in birr</span>
                    </div>
                  )}
                  {widgetConfig.operationType === "Total" && (
                    <div className="widget-body custom-widget-body-1">
                      <h1 className="fw-semi-bold">{Formatter(widgetData)}</h1>
                      <span className="widget-value-sm">
                        {Formatter(widgetData.L6MonthAmount)}{" "}
                      </span>
                      <span>in birr</span>
                    </div>
                  )}
                </div>
              </div>
              {widgetConfig.operationType !== "Total" && (
                <div className="widget-body">
                  <span>
                    Total value of usable health commodities: <span></span>
                  </span>{" "}
                  <span className="widget-value-sm">
                    {Formatter(widgetData.SOHAmount)}
                  </span>
                  <span> birr</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default React.forwardRef(CustomWidget);
