import React from "react";
import styled from "styled-components";
import LandingInfoConstant from "./LandingInfoConstant";

import LandingInfo2 from "./LandingInfo2";
export default function LandingInfo() {
  return (
    <Wrapper>
      <div className="rs-landing-info">
        <div className="flex-row flex-wrap landing-info-wrapper">
          { LandingInfoConstant.map(function (row) {
              return (
                <LandingInfo2 widget_config={row}/>
              );
          })}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .landing-info-wrapper > * {
    margin: 1rem;
  }
  .rs-landing-info {
    max-width: 1300px;
    margin: auto;
    background: white;
    border-radius: 1rem;
    font-family: "Rubik", sans-serif;
  }
  @media screen and (max-width: 750px) {
    .rs-landing-info {
    }
  }
`;
