import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

// custom components
import CustomLoadingIndicator 
  from "./Plugins/CustomLoadingIndicator";
import WidgetInfo 
  from "./Plugins/WidgetInfo";

// Icons
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import { HiExternalLink, HiOutlineOfficeBuilding } from "react-icons/hi";
import { TbMedicineSyrup } from "react-icons/tb";
import { GiMedicines } from "react-icons/gi";

function CustomCarousel({ widget_config, Icon }) {
  const history = useNavigate();

  const styleIcon = {
    color: "white",
    padding: "0.5rem",
    backgroundColor: widget_config.accent_color,
    borderRadius: "1.5rem",
    margin: "0.2rem 0.2rem 0.2rem 0",
    transition: "all 0.3s ease-in-out",
  };

  const MINUTE_MS = 4000;
  const [hovered, setHovered] = useState( false );
  const [selectedCard, setSelectedCard] = useState( 30 );

  useEffect(() => {
    let handle = () => {
      if(!hovered) {
        changeCard(1);
      }
    };
    const interval = setInterval(handle, MINUTE_MS);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [hovered])

  function changeCard(sign) {
    setSelectedCard((prev) => prev + 1 * sign);
  }

  return (
    <>
      <div className="grow-1"
          style={widget_config.value == null ? { opacity: "0.8" } : {}}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}>
            
        <div
          className="main-container custom-card-container flex-row"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          
          <div className="carousel-button">
            <a onClick={() => changeCard(-1)}><MdArrowBackIosNew size={"1em"} /></a>
          </div>
          <div  className="flex-column" style={{ width: "96%" }}>
            <div className="widget-header-container flex-column">
              <div className="flex-row">
                <h6
                  className="widget-header grow-1"
                  style={{ color: widget_config.accent_color }}
                >
                  {widget_config.title + " "}
                  <WidgetInfo widget_config={{
                        title: widget_config.title,
                        page: widget_config.page
                      }}/>
                </h6>
                {widget_config.value == null 
                    && <CustomLoadingIndicator />}
              </div>
              <span>
                <Icon size={40} style={styleIcon} />{" "}
                <span style={{ fontSize: "1.8rem", verticalAlign: "top" }}>
                  { widget_config.values[selectedCard % 3]?.count }
                </span>
                <span style={{ fontSize: "0.8rem" }}> 
                  {widget_config.values[selectedCard % widget_config.card_count]?.label}
                </span>
              </span>
            </div>
            <div className="flex-column">
              {widget_config.type && (
                <span>
                  <span style={{ color: "#475f7b" }}>
                    {widget_config.type === "Facility" && (
                      <HiOutlineOfficeBuilding />
                    )}
                    {widget_config.type === "EPSS" && <TbMedicineSyrup />}
                    {widget_config.type === "Regulatory" && <GiMedicines />}
                  </span>{" "}
                  {widget_config.type}
                </span>
              )}
              {widget_config.link && (
                <span
                  onClick={() => history(widget_config.link)}
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ color: "#475f7b" }}>
                    <HiExternalLink size={25} />
                  </span>
                  <span
                    style={{
                      fontSize: "var(--fs-300)",
                      verticalAlign: "middle",
                    }}
                  >
                    View more
                  </span>
                </span>
              )}
              <p style={{ lineHeight: "1.2rem", minHeight: "2.4rem" }}>
                {widget_config.description} 
              </p>
            </div>
          </div>
          <div className="carousel-button">
            <a onClick={() => changeCard(-1)}><MdArrowForwardIos size={"1em"} /></a>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomCarousel;
