import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collapseSideNav: false,
  setNav: "Dashboard",
  setSupplyChainNav: false,
  showNotification: false,
  notificationContent: {
    type: null,
    title: null,
    message: null,
  },
  token: null,
  selectedChart: null,
  selectedChartData: null,
};

export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    collapseSideNav: (state, action) => {
      state.collapseSideNav = action.payload;
    },
    setNav: (state, action) => {
      state.setNav = action.payload;
    },
    setSupplyChainNav: (state, action) => {
      state.setSupplyChainNav = action.payload;
    },
    showNotification: (state, action) => {
      state.showNotification = true;
      state.notificationContent = action.payload;
    },
    hideNotification: (state) => {
      state.showNotification = false;
      state.notificationContent = initialState.notificationContent;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    removeToken(state) {
      state.token = null;
    },
    showChartDetailModal(state, action) {
      const { selectedChart, selectedChartData } = action.payload;
      state.selectedChart = selectedChart;
      state.selectedChartData = selectedChartData;
    },
    hideChartDetailModal(state) {
      state.selectedChart = null;
      state.selectedChartData = null;
    },
  },
});

export default slice.reducer;
export const appActions = slice.actions;
