import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useRef
} from "react";
import styled from "styled-components";

import { TbStar, TbTriangleInverted, TbTriangle } from "react-icons/tb";
import "./Css/Kpi.css";
import "./Css/Widget.css";
import { Doughnut, Line } from "react-chartjs-2";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { FaDownload, FaExpandAlt, FaPrint } from "react-icons/fa";
import { getFanosData } from "../../Services/dashboardService";
import { KPI_MODAL_COLUMNS } from "./Constants/CustomDataGridConstants";
import {
  POSTIVE_KPI_COLOR_BAND,
  NEGATIVE_KPI_COLOR_BAND,
} from "./Constants/KpiChartConstants";
import { GaugeNeedle } from "./Plugins/GaugeNeedle";
import { useReactToPrint } from "react-to-print";
import CustomDataGrid from "./CustomDataGrid";
import WidgetInfo from "./Plugins/WidgetInfo";

import CustomLoadingIndicator from "./Plugins/CustomLoadingIndicator";
import { CSVLink } from "react-csv";

function KpiChart({ kpiConfig, component_config_detail }, ref) {
  const [kpiData, setKpiData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [kpiColor, setKpiColor] = useState({});

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentTab, setCurrentTab] = useState("Tabular");
  const componentRef = useRef();

  const detailStyle = {
    width: "80%",
    height: "90%"
  }

  function getModalData() {
    let modalData = [];
    for (let index = 0; index < kpiData.dataSet.data.length; index++) {
      modalData.push({
        id: index,
        month: kpiData.dataSet.data[index],
        value: kpiData.dataSet.value[index] + " %",
      });
    }
    return modalData;
  }

  function getExportData() {
    let exportData = [['Id', 'Month', 'Value']];
    for (let index = 0; index < kpiData.dataSet.data.length; index++) {
      exportData.push([
        index,
        kpiData.dataSet.data[index],
        kpiData.dataSet.value[index] + " %",
      ]);
    }
    return exportData;
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function changeTab(tab) {
    setCurrentTab(tab);
  }

  useEffect(() => {
    function fetchPageData() {
      kpiConfig.default_filter_params.kpiId = kpiConfig.kpiId;
      getFanosData(
        kpiConfig.kpi_api,
        kpiConfig.default_filter_params,
        kpiConfig.dataSource
      ).then((data) => {
        var targetData = [];
        data.data.current = parseFloat(data.data.current);
        data.data.target = parseFloat(data.data.target);
        data.data.vsPreviousMonth = (
          data.data.current - data.data.previousMonth
        ).toFixed(1);
        data.data.offTarget =
          parseFloat(data.data.current) >= parseFloat(data.data.target)
            ? 0
            : data.data.target - data.data.current;
    
        for (let index = 0; index < data.data.dataSet.value.length; index++) {
          targetData.push(data.data.target);
        }
        data.data.targetData = targetData;

        setKpiData(data.data);
        setLoading(false);
        data.data.direction
          ? setKpiColor(POSTIVE_KPI_COLOR_BAND)
          : setKpiColor(NEGATIVE_KPI_COLOR_BAND);
        setKpiColor(
          ...kpiColor,
          (kpiColor.warning =
            kpiData.current > kpiData.target ? "#3288bd" : "#d53e4f")
        );
      });
    }
    fetchPageData();
  }, []);

  useImperativeHandle(ref, () => ({
    reload(kpiParamsState) {
      setLoading(true);
      kpiConfig.default_filter_params.kpiId = kpiConfig.kpiId;
      getFanosData(
        kpiConfig.kpi_api,
        kpiParamsState,
        kpiConfig.dataSource
      ).then((data) => {
        var targetData = [];
        data.data.current = parseFloat(data.data.current);
        data.data.target = parseFloat(data.data.target);
        data.data.vsPreviousMonth = (
          data.data.current - data.data.previousMonth
        ).toFixed(1);
        data.data.offTarget =
          parseFloat(data.data.current) >= parseFloat(data.data.target)
            ? 0
            : data.data.target - data.data.current;
        
        for (let index = 0; index < data.data.dataSet.value.length; index++) {
          targetData.push(data.data.target);
        }
        data.data.targetData = targetData;

        setKpiData(data.data);
        setLoading(false);
        data.data.direction
          ? setKpiColor(POSTIVE_KPI_COLOR_BAND)
          : setKpiColor(NEGATIVE_KPI_COLOR_BAND);
        setKpiColor(
          ...kpiColor,
          (kpiColor.warning =
            kpiData.current > kpiData.target ? "#3288bd" : "#d53e4f")
        );
      });
    },
  }));

  if (isLoading) {
    return (
      <div className="kpi-item">
        <div className="kpi-header-container">
          <span className="kpi-header">{kpiConfig.title}</span>
          <CustomLoadingIndicator />
        </div>
      </div>
    );
  } else {
    const config = {
      responsive: true,
      circumference: 180,
      rotation: -90,
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    const data = {
      labels: ["Current", "Off Target"],
      datasets: [
        {
          label: "My First Dataset",
          data: [kpiData.current, kpiData.offTarget],
          needleValue: kpiData.current.toFixed(1),
          backgroundColor: [kpiColor.success, kpiColor.warning],
          hoverOffset: 4,
        },
      ],
    };

    const state = {
      labels: kpiData.dataSet.data,
      datasets: [
        {
          label: "Monthly KPI",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "#3288bd",
          borderColor: "#3288bd",
          borderWidth: 2,
          data: kpiData.dataSet.value,
        },
        {
          label: "Target",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 2,
          data: kpiData.targetData
        },
      ],
    };

    return (
      <>
        <Wrapper className="rs-widget">
          <div>
            <Modal className="rs-dialog"
                  open={open} onClose={handleClose}>
              <Box className="rs-d-container"
                  style={ currentTab === "Detail" ? detailStyle : {} }>
                <div>
                  <div>
                    <p style={{ marginBlockEnd: "0.5rem" }}>{kpiConfig.title}</p>
                  </div>
                  { kpiConfig.tabs &&
                      <ul className="flex-row tab-ul">
                        { kpiConfig.tabs.map(function (tab) {
                            return (<li className={`tab-li ${ currentTab === tab 
                                ? 'tab-li-active' : null }`}>
                              <a onClick={() => changeTab(tab)}>{tab}</a>
                            </li>
                            );
                        })}
                      </ul>
                  }
                </div>
                { currentTab === "Tabular" &&
                    <div>
                      <DataGrid
                        autoHeight
                        rows={getModalData()}
                        columns={KPI_MODAL_COLUMNS}
                        rowHeight={40}
                        animateRows={true}
                      />
                    </div>
                }
                { component_config_detail && currentTab === "Detail" &&
                    <CustomDataGrid component_config={ component_config_detail } />
                }
              </Box>
            </Modal>
          </div>
          <div className="rs-w-header rs-kpi-header">
            <div className="flex-row flex-space-between">
              <div>
                {kpiConfig.title + " "}
                <WidgetInfo widget_config={{
                  title: kpiConfig.title,
                  page: kpiConfig.page
                }}/>
              </div>
              
              <div>
                <div>
                  <CSVLink data={getExportData()}><FaDownload size={"1em"} className="expand-icon-2"/></CSVLink>
                  <FaPrint onClick={handlePrint} size={"1em"} className="expand-icon-2"/>
                  <FaExpandAlt className="expand-icon-2" onClick={handleOpen} />
                </div>
              </div>
              {/* <span><TbStar size={20} style={{ fill: "green", color:"green", verticalAlign:"baseline" }}/></span> */}
            </div>
          </div>
          <div className="rs-w-body">
            <div className="flex-column" ref={componentRef}>
              <div>
                <div className="flex-row flex-space-between flex-item-center">
                  <div className="kpi-current-chart flex-stretch">
                    <Doughnut
                      options={config}
                      data={data}
                      plugins={[
                        GaugeNeedle("kpi-current", kpiData.current, data, {
                          text: "Current",
                        }),
                        GaugeNeedle("kpi-target", kpiData.target, data, {
                          text: "Target",
                          dashed: true,
                          textYOffset: 40,
                        }),
                      ]}
                    />
                  </div>
                  <div className="kpi-vs-previous">
                    <div className="flex-column">
                      <div>
                        {kpiData.percentIncreaseFromPrevious < 0 || (
                          <span>
                            <TbTriangle
                              size={20}
                              style={{
                                fill: kpiColor.success,
                                color: kpiColor.success,
                                verticalAlign: "baseline",
                              }}
                            />{" "}
                          </span>
                        )}
                        {kpiData.percentIncreaseFromPrevious > 0 || (
                          <span>
                            <TbTriangleInverted
                              size={20}
                              style={{
                                fill: kpiColor.warning,
                                color: kpiColor.warning,
                                verticalAlign: "baseline",
                              }}
                            />{" "}
                          </span>
                        )}
                        <span className="kpi-value">
                          {" "}
                          {Math.abs(Math.abs(kpiData.percentIncreaseFromPrevious))} %
                        </span>
                      </div>
                      <div>
                        <span>vs. previous</span>
                        <br />
                        <span> month</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {kpiConfig.targetSpecified && (
                <div>
                  <Line
                    data={state}
                    options={{
                      scales: {},
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Wrapper>
      </>
    );
  }
}

const Wrapper = styled.div`
  min-width: 23rem;
  min-height: 15rem;
  max-width: 30rem;

  .rs-kpi-header {
    position: relative;
    z-index: 1;
  }
  .kpi-current-chart {
    width: 70%;
    margin-top: -3.5rem;
    max-width: 15rem;
  }
  .kpi-vs-previous {
    width: 30%;
  }
`
export default React.forwardRef(KpiChart);
