import React from "react";
import Navbar from "../../../shared/Navigation/Navbar";
import SideNav from "../../../shared/Navigation/SideNav";
import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import styled from "styled-components";
import under_construction from "../../../Assets/Images/under_construction.png";
function LocalManufacturer() {
  const app = useSelector(selectors.app);

  return (
    <Wrapper>
      
      <div className="container-context">
        
        <div className="container-right">
          <div style={{ textAlign: "center" }}>
            <img
              style={{ width: "30rem" }}
              src={under_construction}
              alt="loading"
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
 
  @media screen and (max-width: 1025px) {
    .container-body {
    display: flex;
    flex-direction: column;
  }
`;
export default LocalManufacturer;
