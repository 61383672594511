
import React, { 
  useState 
} from "react";

export function GridLegend({ component_config, on_click_handler, class_name }) {
  const [activeLegend, setActiveLegend] = useState({});

  const filterGridData = (legend) => {
    on_click_handler(legend, legend.label !== activeLegend.label);
    if (legend.label !== activeLegend.label) 
      setActiveLegend(legend);
    else 
      setActiveLegend({});
  };

  return (  
    <div className="legend">
      <div className={class_name}>
        {component_config.legend?.map(function (legend) {
          return (
              <button
                onClick={() => {
                  filterGridData(legend);
                }}
              >
                <span
                  className="legend-bar"
                  style={{
                    backgroundColor: legend.color,
                    color:
                      activeLegend.label === legend.label
                        ? "white"
                        : legend.color,
                    fontSize:
                      activeLegend.label === legend.label
                        ? "1rem"
                        : "0.8rem",
                  }}
                >
                  __
                </span>
                <span className="legend-label">{legend.label}</span>
              </button>
          );
        })}
      </div>
    </div>
  );
}

export default GridLegend;

