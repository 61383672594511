export const KPI_DEFAULT_FILTER_PARAMS = {
  dateFrom: '2023-07-08',
  fiscalYear: 2015,
  kpiId: 2
}

export const POSTIVE_KPI_COLOR_BAND = {
  success: "#3288bd",
  warning: "#d53e4f"
}

export const NEGATIVE_KPI_COLOR_BAND = {
  success: "#d53e4f",
  warning: "#3288bd"
}

const KPI_CHARTS_CONSTANTS = [
  KPI_DEFAULT_FILTER_PARAMS
]

export default KPI_CHARTS_CONSTANTS;
