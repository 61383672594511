import React, { useEffect, useState } from "react";
import Select, { components } from 'react-select'
import {
  getFanosData
} from "../../Services/dashboardService";

import { GetUserRegions } from "../Helpers/PermissionHelper";

function CustomSelect({ handle_change, component_config }) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let [options, setOptions] = useState([]);
  const [optionsFiltered, setOptionsFiltered] = useState([]);
  const [parentFilter, setParentFilter] = useState({});
  const [isLoading, setLoading] = useState(true);
  let defaultOption = component_config.default_option;

  useEffect(() => {
    async function fetchOptions() {
      if(component_config.options 
          && component_config.option_type 
          && component_config.option_type == "hardCode"){
        setLoading(false);
        setOptions(component_config.options);
        setOptionsFiltered(component_config.options);
      } else {
        await getFanosData(component_config.api_url, {}, component_config.data_source).then((data) => { 
          let optionData = component_config.options_data_property ? data.data[component_config.options_data_property] : data.data;
          if(component_config.hidden_options) {
            optionData = optionData.filter(option => {
              return !component_config.hidden_options.includes(option[component_config.label_property]);
            })
          }
          else if(component_config.picked_list) {
            optionData = optionData.filter(option => {
              return component_config.picked_list.includes(option[component_config.picked_list_column]);
            })
          }
          setParentFilter(component_config.parent_filter);
          
          if(component_config.check_permission) {
            optionData = GetUserRegions(optionData, userInfo);
            optionData = optionData.filter(option => {
              return !component_config.hidden_options.includes(option[component_config.label_property]);
            })
          }
          configureOptions(optionData);
          setOptions(optionData.slice());
          setOptionsFiltered(optionData.slice());
          setLoading(false);
        });
      }
    }
    fetchOptions();
  }, []);

  function configureOptions(data) {
    data.forEach(option => {
      option.label = option[component_config.label_property];
      option.value = option[component_config.value_property];
    });
  }

  const handleChange = (selectedOption) => {
    if(selectedOption?.label == "Federal" 
        && component_config.param_name == "regionId") {
      localStorage.setItem("showFederalOnly", "showFederalOnly");
    }
    else if (selectedOption?.label != "Federal" 
        && component_config.param_name == "regionId") {
      localStorage.setItem("showFederalOnly", "");
    }
    if(selectedOption != null)
      handle_change({ paramName: component_config.param_name, paramValue: selectedOption.value })
    else
      handle_change({ paramName: component_config.param_name, paramValue: null })
  };

  const onFocus = () => {
    if(parentFilter) {      
      const showFederalOnly = localStorage.getItem("showFederalOnly");
      let filtered = options;
      if(showFederalOnly == "showFederalOnly"){
        filtered = options.filter(option => {
          return component_config.xpicked_list.includes(option[component_config.picked_list_column]);
        });
      }
      else {
        filtered = options;
      }

      if(parentFilter.value) {
        filtered = filtered.filter(option => {
          return option[parentFilter.value_property] === parentFilter.value 
              || option[parentFilter.value_property] === null
              || option[parentFilter.value_property] === ""
        });
        setOptionsFiltered(filtered);
      }
      else {
        setOptionsFiltered(filtered);
      }
    }
  };

  return (
    <>
    <Select options={optionsFiltered} 
        isLoading={isLoading} isClearable={ component_config.is_clearable === undefined }
        placeholder={component_config.place_holder} 
        onChange={ handleChange } 
        defaultValue={ defaultOption || 'Select' }
        onFocus={ onFocus }/>
    </>
  );
}

export default CustomSelect;