import React, { useState, useEffect } from "react";
import Navbar from "../../../shared/Navigation/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "../../../store";
import styled from "styled-components";
import CustomDataGrid from "../../../shared/Charts/CustomDataGrid";
import { getRegisteredProductsRawDataEris } from "../../../Services/dashboardService";
import { registeredProductsColumns } from "../../../shared/Charts/Constants/CustomDataGridConstants";
import SideNav from "../../../shared/Navigation/SideNav";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbar,
} from "@mui/x-data-grid";
import CustomSelect from "../../../shared/Inputs/CustomSelect";
import { FN_PROGRAM_SELECT_CONFIG } from "../../../shared/Inputs/SelectConstants";
import Select, { components } from 'react-select'

export default function RegisteredProducts() {
  const app = useSelector(selectors.app);
  const [searchParam, setSearchParam] = useState([]);
  const [productType, setProductType] = useState([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 100,
  });
  const options = [
    {label: "Medicine", value: 'MDCN'},
    {label: "Food", value: 'FD'},
    {label: "Medical Device", value: 'MD'},
  ]
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: "#15616d" }} />
        <GridToolbarFilterButton style={{ color: "#15616d" }} />
        <GridToolbarExport style={{ color: "#15616d" }} />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));
    getRegisteredProductsRawDataEris(
      "/99",
      pageState.pageSize,
      pageState.page,
      searchParam,
      productType,
    ).then((data) => {
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data.data.data,
        total: data.data.recordsTotal,
      }));
    });
  }, [pageState.pageSize, pageState.page, searchParam, productType]);

  const handleChange = (selectedOption) => {
    selectedOption != null?
    setProductType(selectedOption.value):
    setProductType('');
  };

  return (
    <Wrapper>
      
      <div className="container-context">
        
        <div className="container-right">
          <div className="container-body">
            <div className="header">
              <h4>Registered Products</h4>
            </div>
            <div className="tool-bar-item tool-bar-item-1 tool-bar-item-2">
              <Select options={options} 
                  isClearable={true}
                  placeholder={"Product Type"} 
                  onChange={ handleChange } />
            </div>
            <DataGrid
              style={{ margin: "1rem", backgroundColor: "white" }}
              autoHeight
              rows={pageState.data}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              rowsPerPageOptions={[10, 30, 50, 70, 100, 1000]}
              pagination
              density={"standard"}
              columns={registeredProductsColumns}
              getRowId={(row) => row.rowNumber}
              headerHeight={95}
              rowHeight={50}
              Page={pageState.page - 1}
              PageSize={pageState.pageSize}
              onPageChange={(newPage) =>
                setPageState((old) => ({ ...old, page: newPage + 1 }))
              }
              onPageSizeChange={(newPageSize) =>
                setPageState((old) => ({ ...old, pageSize: newPageSize }))
              }
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 600 },
                  onChange: (event) => setSearchParam(event.target.value),
                  clearSearch: () => {
                    setSearchParam([]);
                  },
                },
              }}
              disableDensitySelector
              animateRows={true}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  .header {
    color: #475f7b;
  }
  .container-body {
    font-family: "Rubik", sans-serif;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1025px) {
    .container-body {
      display: flex;
      flex-direction: column;
    }
  }
`;
