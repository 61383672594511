import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";

// custom components
import "./dashboard.css";
import CustomCard from "../../shared/Charts/CustomCard";
import CustomCarousel from "../../shared/Charts/CustomCarousel";

import {
  DAGU_FACILITIES,
  SYNCED_FACILITIES_COLUMNS
} from "../../shared/Charts/Constants/CustomCardConstants";

// icons
import { TbRefresh, TbCheckbox, TbBuildingWarehouse } from "react-icons/tb";
import {
  MdLocalHospital,
  MdOutlineAppRegistration,
  MdMedicalServices,
  MdOutlineHomeWork,
} from "react-icons/md";
import { GiMedicinePills } from "react-icons/gi";

// data service
import {
  getSummary,
  getImportedAmount,
  getFanosData,
  getRegisteredProductsEris
} from "../../Services/dashboardService";
import CustomBarChart from "../../shared/Charts/CustomBarChart";

// constants
import {
  LEGEND_RECENTLY_SYNCED_FACILITIES
} from "../../shared/Charts/Constants/CustomDataGridConstants";

// helpers
import { DefaultChartDataFormatter } from "../../shared/Helpers/ChartDataHelper";
import { RecentlySyncedDataFilter } from "../../shared/Helpers/DataServiceHelper";
export default function Dashboard() {
  const [summary, setSummary] = useState([]);

  const [pharmacuticalSummary, setPharmacuticalSummary] = useState(null);
  const [pharmacuticalSummary2, setPharmacuticalSummary2] = useState([]);
  const [medicalDeviceSummary, setMedicalDeviceSummary] = useState(null);
  const [medicalDeviceSummary2, setMedicalDeviceSummary2] = useState([]);
  const [registeredProducts, setRegisteredProducts] = useState(null);
  const [registeredProductsLabeled, setRegisteredProductsLabeled] = useState([]);
  const [totalFacilities, setTotalFacilities] = useState(null);
  const [totalSyncedFacilities, setTotalSyncedFacilities] = useState(null);
  const [recentlySynced, setRecentlySynced] = useState(null);

  const [importedAmount, setImportedAmount] = useState([]);

  useEffect(() => {
    getSummary().then((data) => {
      setSummary(data.data.Data);
      for (let summary of data.data.Data) {
        if (summary?.CommodityType === "Medical Equipments") {
          setMedicalDeviceSummary(formatter(summary?.TotalSOHAmount));
          setMedicalDeviceSummary2([ 
            { label: "", count: formatter(summary?.TotalSOHAmount)},
            { label: "(Center)", count: formatter(summary?.CenterSOHAmount)}, 
            { label: "(Hubs)", count: formatter(summary?.HubsSOHAmount)}
          ]);
        } 
        else if(summary?.CommodityType === "Pharmaceuticals") {
          setPharmacuticalSummary(formatter(summary?.TotalSOHAmount));
          setPharmacuticalSummary2([ 
            { label: "", count: formatter(summary?.TotalSOHAmount)},
            { label: "(Center)", count: formatter(summary?.CenterSOHAmount)}, 
            { label: "(Hubs)", count: formatter(summary?.HubsSOHAmount)}
          ]);
        }
      }
    });

    getRegisteredProductsEris("/96").then((data) => {
      //let foodCount = 0;
      let medicineCount = 0;
      let deviceCount = 0;
      for (let entry of data.data.data) {
        // if (entry.product_type === "Food") {
        //   foodCount += entry.count;
        // }
        if (entry.product_type === "Medicine") {
          medicineCount += entry.count;
        }
        if (entry.product_type === "Medical Device") {
          deviceCount += entry.count;
        }
      }

      setRegisteredProducts(formatter(medicineCount + deviceCount));
      setRegisteredProductsLabeled([ 
          { label: "", count: formatter(medicineCount + deviceCount)},
          { label: "(Medicine)", count: formatter(medicineCount)}, 
          { label: "(Medical Device)", count: formatter(deviceCount)}
        ]);
    });
    
    getImportedAmount().then((data) => {
      setImportedAmount(data.data.data.sort((a, b) => a.name - b.name));
    });
    getFanosData(
      "/Dashboard/GetFacilitySyncStatusByRegionId",
      {},
      "dagu",
      "get",
      true
    ).then((data) => {
      setTotalSyncedFacilities(data.data.model.length);
      var recentlySyncedFacilities = data.data.model.filter((facility) => {
        return (
          moment(facility.lastSyncDate).diff(moment().subtract(90, "days")) >= 0
        );
      });
      setRecentlySynced(recentlySyncedFacilities.length);
    });

    getFanosData(
      "https://jsi-odk-micro.onrender.com/daguMasterList",
      {},
      "directUrl",
      "get",
      false
    ).then((data) => {
      setTotalFacilities(formatter(data?.data?.totalCount));
    });
  }, [totalFacilities]);

  function formatter(value) {
    if (value < 1e3) return value;
    if (value > 1e3 && value < 1e6) return (value / 1e3).toFixed(1) + "K";
    if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(1) + "M";
    if (value >= 1e9) return +(value / 1e9).toFixed(1) + "B";
  }

  return (
    <Wrapper>
      <div className="container-context">
            <div className="main-body">
        <div className="main-body-container">
              <div className="flex-row flex-wrap">
                <CustomCard
                  widget_config={{                  
                    page: "dashboard",
                    title: "Hubs",
                    description: "EPSS hubs including home office",
                    value: "20",
                    type: "EPSS",
                    accent_color: "#D4AF37",
                  }}
                  Icon={TbBuildingWarehouse}
                />
                <CustomCarousel
                  widget_config={{                  
                    page: "dashboard",
                    title: "Pharmaceutical",
                    description: "Total worth of pharmaceutical",
                    value: pharmacuticalSummary,
                    values: pharmacuticalSummary2,
                    type: "EPSS",
                    accent_color: "#7b519d",
                    card_count: 3
                  }}
                  Icon={GiMedicinePills}
                />
                <CustomCarousel
                  widget_config={{                  
                    page: "dashboard",
                    title: "Medical Device",
                    description: "Total worth of medical device",
                    value: medicalDeviceSummary,
                    values: medicalDeviceSummary2,
                    type: "EPSS",
                    accent_color: "#93be52",
                    card_count: 3
                  }}
                  Icon={MdMedicalServices}
                />
                <CustomCard
                  widget_config={{                  
                    page: "dashboard",
                    title: "EFDA Branches",
                    description: "EFDA regional branches including home office",
                    value: 7,
                    type: "Regulatory",
                    accent_color: "#1ab394",
                  }}
                  Icon={MdOutlineHomeWork}
                />
                <CustomCard
                  widget_config={{                  
                    page: "dashboard",
                    title: "Total Facilities",
                    description: "Running Dagu 2.1",
                    value: totalFacilities,
                    type: "Facility",
                    accent_color: "#93be52",
                    modal_config: {
                      title: "Total Facilities Running Dagu 2.1",
                      grid_config: {
                        columns: DAGU_FACILITIES,
                        data_source_api: "https://jsi-odk-micro.onrender.com/dagudata",
                        data_source: "directUrl",
                        data_property: "",
                        select_filter_config: [],
                        defaultFilterParams: {},
                        density: "compact",
                        modal_view: true
                      }
                    }
                  }}
                  Icon={MdLocalHospital}
                />
                <CustomCard
                  widget_config={{                  
                    page: "dashboard",
                    title: "Synced Facilities",
                    description: "Total synced facilities",
                    value: totalSyncedFacilities,
                    type: "Facility",
                    accent_color: "#4680ff",
                    modal_config: {
                      title: "Total Synced Facilities",
                      grid_config: {
                        columns: SYNCED_FACILITIES_COLUMNS,
                        data_source_api: "/Dashboard/GetFacilitySyncStatusByRegionId",
                        data_source: "dagu",
                        data_property: "model",
                        select_filter_config: [],
                        defaultFilterParams: {},
                        density: "compact",
                        modal_view: true,
                        use_default_filter_params: true,
                      }
                    }
                  }}
                  Icon={TbCheckbox}
                />
                <CustomCard
                  widget_config={{                  
                    page: "dashboard",
                    title: "Recently Synced",
                    description: "Last 90 days",
                    value: recentlySynced,
                    type: "Facility",
                    accent_color: "#fc6180",
                    modal_config: {
                      title: "Recently Synced Facilities",
                      grid_config: {
                        columns: SYNCED_FACILITIES_COLUMNS,
                        data_source_api: "/Dashboard/GetFacilitySyncStatusByRegionId",
                        data_source: "dagu",
                        data_manipulator_2: RecentlySyncedDataFilter,
                        data_property: "model",
                        select_filter_config: [],
                        defaultFilterParams: {},
                        density: "compact",
                        modal_view: true,
                        use_default_filter_params: true,
                        legend: LEGEND_RECENTLY_SYNCED_FACILITIES
                      }
                    }
                  }}
                  Icon={TbRefresh}
                />
                <CustomCarousel
                  widget_config={{                  
                    page: "dashboard",
                    title: "Registered Products",
                    description: "National registered products",
                    value: registeredProducts,
                    values: registeredProductsLabeled,
                    type: "Regulatory",
                    accent_color: "#526573",
                    card_count: 3
                  }}
                  Icon={MdOutlineAppRegistration}
                />
              </div>
              <div className="flex-row flex-wrap" style={{ justifyContent: "space-around"}}>
                <div className="grow-1">
                <CustomBarChart
                  data={importedAmount}
                  label={["Amount (In Birr)"]}
                  fields={["value", "name"]}
                  widget_config={{
                    title: "Imported Amount Yearly Trend",
                    showExpandAlt: true,
                    axis: "x",
                    data_formatter: DefaultChartDataFormatter
                  }}
                />
                </div>
            </div>
            </div>
          </div>
          </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  .main-body-container {
  }
  .container-body {
    display: flex;
    background-color: rgb(233, 238, 243);
  }
  .left-container {
    width: 100%;
    margin: 10px;
  }
  .card {
    display: flex;
    flex-grow: 1;
    min-width: 9.2rem;
    height: 7.5rem;
    margin: 0.5rem;
    background: white;
    box-shadow: 0px 1px 4px 0px grey;
    text-align: center;
  }
`;