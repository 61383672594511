import React, { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

import { 
  Checkbox,
  FormControlLabel 
} from "@mui/material";

import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import "./supplyChain.css";
import CustomSelect from "../../../shared/Inputs/CustomSelect";
import WorkflowCard from "../../../shared/Charts/WorkflowCard";

import { ScrollToElement } from "../../../shared/Helpers/UIHelper";

import { TbAward, TbCheckbox, TbBuildingWarehouse } from "react-icons/tb";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdOutlineChecklistRtl, MdAttachMoney, 
    MdOutlinePrint, MdOutlineCreditScore } from "react-icons/md";
import { CiCircleList } from "react-icons/ci";

import {
  STOCK_STATUS_DEFAULT_FILTER_PARAMS,
  PROCUREMENT_STATUS_DEFAULT_FILTER_PARAMS,
  PROCUREMENT_STATUS_COLUMNS,
  STOCK_STATUS_COLUMNS,
  LEGEND_STOCK_STATUS,
  LEGEND_PROCUREMENT_STATUS,
  PO_RECEIVES_COLUMNS,
  WASTAGE_RATE_RAW_COLUMNS,
  PRODUCT_AVAILABILITY_RAW_COLUMNS,
  CHART_CONFIG_STOCK_STATUS,
  CHART_CONFIG_PROCUREMENT_STATUS,
  NEAR_EXPIRY_COLUMNS
} from "../../../shared/Charts/Constants/CustomDataGridConstants";

import {
  ENVIRONMENT_SELECT_CONFIG,
  MODE_SELECT_CONFIG,
  PROGRAM_SELECT_CONFIG,
  FN_COMMODITY_TYPE_ID_SELECT_CONFIG,
  FN_ENVIRONMENT_ID_SELECT_CONFIG,
  FN_SS_ENVIRONMENT_ID_SELECT_CONFIG,
  FN_MODE_ID_SELECT_CONFIG,
  FN_PROGRAM_ID_SELECT_CONFIG,
  FN_PROGRAM_SELECT_CONFIG,
  PO_TYPE_SELECT_CONFIG
} from "../../../shared/Inputs/SelectConstants";
import { KPI_DEFAULT_FILTER_PARAMS } from "../../../shared/Charts/Constants/KpiChartConstants";
import KpiChart from "../../../shared/Charts/KpiChart";
import CustomDataGrid from "../../../shared/Charts/CustomDataGrid";
import moment from "moment";
import styled from "styled-components";
import CustomCard2 from "../../../shared/Charts/CustomCardRatio";

import { EpssStockStatusDM, ProcurementStatusDM } from "../../../shared/Helpers/DataManipulationHelper";

import { ShowProcurementStatusDetail } from "../../../shared/Helpers/UIHelper";

function SupplyChain() {
  const params = useParams();

  const PAGE = "supply_chain";
  const app = useSelector(selectors.app);
  const productAvailablityRef = useRef(null);
  const model19CollectionRef = useRef(null);
  const lineFillRateRef = useRef(null);
  //const stockedAccordingToPlanRef = useRef(null);
  const wastageRateRef = useRef(null);
  const nearExpiryRateRef = useRef(null);
  const iconSize = 35;
  const poLeadTimeIcons = [ 
    <CiCircleList fontWeight="bold" color="blue" size={iconSize}/>, 
    <TbAward color="gold" size={iconSize}/>, 
    <MdOutlineChecklistRtl color="green" size={iconSize}/>, 
    <FaMoneyCheckAlt color="gold" size={iconSize}/>, 
    <MdAttachMoney color="green" size={iconSize}/>, 
    <MdOutlineCreditScore color="green" size={iconSize}/>, 
    <MdOutlinePrint color="green" size={iconSize}/> ];

  const selects = [
    ENVIRONMENT_SELECT_CONFIG,
    MODE_SELECT_CONFIG,
    PROGRAM_SELECT_CONFIG,
    FN_COMMODITY_TYPE_ID_SELECT_CONFIG,
  ];

  const kpiApi1 = "/KPI/GetPMDProductAvailability?";
  const kpiApi2 = "/KPI/GetPMDModel19Collection?";
  const kpiApi3 = "/KPI/GetPMDStockingEfficiency?";
  const kpiApi4 = "/KPI/GetPMDWastageRate?";
  const kpiApiLineFillRate = "/KPI/GetPMDLineFillRateData?";
  const sohApi = "/EHPMD_WebApi/EHPMD_StockStatus?";
  const nearExpiryRateApi = "/EHPMD_WebApi/PotentialExpiry?";
  const NearExpiryRawData = "/EHPMD_WebApi/PotentialExpiryRawData";
  const procurementStatusApi = "/EHPMD_WebApi/ProcurementStatus?";
  const pageFilterParams = KPI_DEFAULT_FILTER_PARAMS;
  const dayOfMonth = parseInt(moment().format('D'));
  pageFilterParams.dateTo = dayOfMonth >= 15
      ? moment().subtract(1, "days").format("YYYY-MM-DD")
      : moment().subtract(dayOfMonth, "days").format("YYYY-MM-DD");

  const onChangeCheckbox = (event, value) => {
    handleChange({ paramName: "IsTracer", paramValue: value });
  };
  pageFilterParams.IsTracer = true;

  const handleChange = (param) => {
    pageFilterParams[param.paramName] = param.paramValue;
    
    if(param.paramName !== "IsTracer") {
      model19CollectionRef.current.reload(pageFilterParams);
      //stockedAccordingToPlanRef.current.reload(pageFilterParams);
    }
    wastageRateRef.current.reload(pageFilterParams);
    productAvailablityRef.current.reload(pageFilterParams);
    nearExpiryRateRef.current.reload(pageFilterParams);
    lineFillRateRef.current.reload(pageFilterParams);
  };

  useEffect(() => {
    ScrollToElement(document.getElementById(params.section_id));
  }, []);

  return (
    <Wrapper>
      
      <div className="container-context">
        <div className="main-body">
          <div className="main-body-container">
            <div className="section-main">
              <div className="flex-column section-main-container">
                <div className="section-header">
                  <div className="section-header-container">
                    <p className="section-header-title">KPI</p>
                  </div>
                </div>
                <div className="widget-toolbar">
                  <div className="flex-row flex-wrap widget-toolbar-container">
                    {selects.map(function (config) {
                      return (
                        <div className="toolbar-item">
                          <CustomSelect
                            handle_change={handleChange}
                            component_config={config}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex-row widget-toolbar-container">
                    <FormControlLabel
                        control={
                          <Checkbox
                            name={"IsTracer"}
                            onChange={ onChangeCheckbox }
                            defaultChecked 
                          />
                        }
                        label={"Show tracer items only"}
                      />
                  </div>
                </div>
                <div className="section-body">
                  <div className="flex-row flex-wrap section-body-container">
                    <KpiChart
                      ref={productAvailablityRef}
                      kpiConfig={{
                        page: PAGE,
                        title: "Product Availability",
                        kpi_api: kpiApi1,
                        default_filter_params: pageFilterParams,
                        dataSource: "kpi",
                        targetSpecified: true,
                        kpiId: 2,
                        tabs: ["Tabular", "Detail"],
                      }}
                      component_config_detail={{
                        columns: PRODUCT_AVAILABILITY_RAW_COLUMNS,
                        data_source_api: "/KPI/GetPMDProductAvailabilityRawData?",
                        data_source: "kpi",
                        data_property: null,
                        select_filter_config: [
                          FN_COMMODITY_TYPE_ID_SELECT_CONFIG,
                          FN_ENVIRONMENT_ID_SELECT_CONFIG,
                        ],
                        defaultFilterParams: {},
                        density: "compact",
                        modal_view: true,
                        use_default_filter_params: true,
                      }}
                    />
                    <CustomCard2
                      ref={nearExpiryRateRef}
                      widgetConfig={{                     
                        page: PAGE,
                        title: "Potential to Expiry Rate",
                        data_source_api: nearExpiryRateApi,
                        source: "fanos",
                        method: "get",
                        default_filter_params: pageFilterParams,
                        used_filters_list: [
                          "CommodityTypeID",
                          "EnvironmentId",
                          "ModeId",
                          "ProgramId",
                          "IsTracer"
                        ],
                        numeratorColumn: "PotentialExpiryAmount",
                        denominatorColumn: "SOHAmount",
                        percentColumn: "PotentialExpiryPercentage",
                        unit: "birr"
                      }}
                      component_config_detail={{
                        page: PAGE,
                        title: "Potential to Expiry Rate",
                        columns: NEAR_EXPIRY_COLUMNS,
                        data_source_api: NearExpiryRawData,
                        data_property: "Data",
                        defaultFilterParams: { IsTracer: true },
                        select_filter_config: [
                          FN_MODE_ID_SELECT_CONFIG,
                          FN_ENVIRONMENT_ID_SELECT_CONFIG,
                          FN_COMMODITY_TYPE_ID_SELECT_CONFIG,
                        ],
                          checkbox_filter_config: [
                            {
                              param_name: "IsTracer", label: "Show Tracer Items", default_checked: true
                            }
                          ],
                        defaultFilterParams: {},
                        density: "compact"
                      }}
                    />
                    <KpiChart
                      ref={wastageRateRef}
                      kpiConfig={{
                        page: PAGE,
                        title: "Wastage Rate",
                        kpi_api: kpiApi4,
                        default_filter_params: pageFilterParams,
                        dataSource: "kpi",
                        targetSpecified: true,
                        kpiId: 4,
                        tabs: ["Tabular", "Detail"],
                      }}
                      component_config_detail={{
                        columns: WASTAGE_RATE_RAW_COLUMNS,
                        data_source_api: "/KPI/GetPMDWastageRateRawData?",
                        data_source: "kpi",
                        data_property: null,
                        select_filter_config: [
                          FN_COMMODITY_TYPE_ID_SELECT_CONFIG,
                          FN_ENVIRONMENT_ID_SELECT_CONFIG,
                        ],
                        defaultFilterParams: {},
                        density: "compact",
                        modal_view: true,
                        use_default_filter_params: true,
                      }}
                    />
                    <KpiChart
                      ref={model19CollectionRef}
                      kpiConfig={{
                        page: PAGE,
                        title: "POD collection",
                        kpi_api: kpiApi2,
                        default_filter_params: pageFilterParams,
                        targetSpecified: true,
                        dataSource: "kpi",
                        kpiId: 19,
                      }}
                    />
                    <KpiChart
                      ref={lineFillRateRef}
                      kpiConfig={{
                        page: PAGE,
                        title: "Line Fill Rate",
                        kpi_api: kpiApiLineFillRate,
                        default_filter_params: pageFilterParams,
                        targetSpecified: true,
                        dataSource: "kpi",
                        kpiId: 19,
                      }}
                    />
                  </div>
                  {/* <div className="section-widgets-container">
                    <KpiChart
                      ref={stockedAccordingToPlanRef}
                      kpiConfig={{
                        title: "Stocked According To Plan",
                        kpi_api: kpiApi3,
                        default_filter_params: pageFilterParams,
                        dataSource: "kpi",
                        targetSpecified: true,
                        kpiId: 15,
                      }}
                    />
                  </div> */}
                </div>
                </div>
              </div>

              <div id="epss-stock-status-section" className="section-main-container">
                <div className="section-main">
                  <div className="section-header-container">
                    <p className="section-header-title">Stock Status</p>
                  </div>
                  <div className="section-body">
                    <div className="section-widgets-container section-widgets-container-data-grid">
                      <CustomDataGrid
                        component_config={{
                          page: PAGE,
                          page: PAGE,
                          title: "Product Stock Status",
                          data_source_api: sohApi,
                          data_manipulator: EpssStockStatusDM,
                          data_property: "Data",
                          columns: STOCK_STATUS_COLUMNS,
                          select_filter_config: [
                            FN_COMMODITY_TYPE_ID_SELECT_CONFIG,
                            FN_SS_ENVIRONMENT_ID_SELECT_CONFIG,
                            FN_MODE_ID_SELECT_CONFIG,
                            FN_PROGRAM_SELECT_CONFIG,
                          ],
                          checkbox_filter_config: [
                            { 
                              param_name: "IsPPL", label: "Show PPL Items", default_checked: true
                            },
                            {
                              param_name: "IsTracer", label: "Show Tracer Items", default_checked: true
                            }
                          ],
                          defaultFilterParams:
                            STOCK_STATUS_DEFAULT_FILTER_PARAMS,
                          legend: LEGEND_STOCK_STATUS
                        }}
                        chart_config={CHART_CONFIG_STOCK_STATUS}
                      />
                    </div>
                  </div>
                </div>
                <div id="procurement-section" className="section-main">
                  <div className="section-header-container">
                    <p className="section-header-title">Procurement</p>
                  </div>
                  <div className="section-body">
                    <div className="flex-column">
                      <WorkflowCard
                        widget_config={{                  
                          page: PAGE,
                          title: "Procurement Lead Time",
                          description: "Procurement Lead Time",
                          data_source_api: "/EHPMD_WebApi/ProcurementLeadTime",
                          startProcess: "Request",
                          endProcess: "GRV",
                          icons: poLeadTimeIcons
                        }}
                        Icon={TbBuildingWarehouse}
                      />
                    </div>
                  </div>
                  <div id="procurement-status-section" className="section-body">
                    <div className="section-widgets-container section-widgets-container-data-grid">
                      <CustomDataGrid
                        component_config={{
                          page: PAGE,
                          title: "Procurement Status",
                          data_source_api: procurementStatusApi,
                          data_property: "Data",
                          data_manipulator: ProcurementStatusDM,
                          columns: PROCUREMENT_STATUS_COLUMNS,
                          select_filter_config: [
                            FN_COMMODITY_TYPE_ID_SELECT_CONFIG,
                            FN_MODE_ID_SELECT_CONFIG,
                            FN_PROGRAM_ID_SELECT_CONFIG,
                            PO_TYPE_SELECT_CONFIG
                          ],
                          checkbox_filter_config: [
                            { param_name: "IsAggregate", label: "Show detail", invert: true },
                          ],
                          defaultFilterParams:
                            PROCUREMENT_STATUS_DEFAULT_FILTER_PARAMS,
                          legend: LEGEND_PROCUREMENT_STATUS
                        }}
                        chart_config={CHART_CONFIG_PROCUREMENT_STATUS}
                        component_config_detail={{
                          page: PAGE,
                          title: "PO Receives",
                          columns: PO_RECEIVES_COLUMNS,
                          data_source_api: "/EHPMD_WebApi/POReceives",
                          data_property: "Data",
                          select_filter_config: [],
                          defaultFilterParams: {},
                          density: "compact",
                          use_default_filter_params: true,
                          show_detail: ShowProcurementStatusDetail,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;

  // @media screen and (max-width: 1025px) {
  //   .ain-body-container {
  //   display: flex;
  //   flex-direction: column;
  // }
`;
export default SupplyChain;
