import React, {
	useState,
	useEffect
} from "react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { HiOutlineInformationCircle } from "react-icons/hi";

import {
	getFanosData,
	saveRecord
} from "../../../Services/dashboardService";

export function WidgetInfo({ widget_config }) {
	const saveWidgetInfoAPI = "/EHPMD_WebApi/SaveWidgetInfo";

	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [editMode, setEditMode] = useState(false);
	const [widgetInfo, setWidgetInfo] = useState(false);
	const widgetCode = widget_config.title.replace(" ", "") + widget_config.page;

	useEffect(() => {
		getFanosData("/EHPMD_WebApi/GetWidgetByCode?code=" + widgetCode)
				.then((data) => {
			setWidgetInfo(data.data);
		});
	}, []);

	useEffect(() => {
		if(submitted) {
			save();
			setSubmitted(false);
		}
	}, [submitted]);

	function clickHandler() {
		if(!widgetInfo.WidgetID) {
			setWidgetInfo({ ...widgetInfo, 
					Name: widget_config.title,
					Code: widget_config.title.replace(" ", "") + widget_config.page 
				});
		}
		setSubmitted(true);
	}

	function save() {
		saveRecord(saveWidgetInfoAPI, widgetInfo)
			.then((data) => {
				setWidgetInfo(data.data)
				setSuccess(true);
				setEditMode(false);
			})
			.catch((err) => {
				setError(true);
				setTimeout(() => {
					setError(false);
				}, 10000);
			});
	}

	return (
		<>
			<span>
				<HiOutlineInformationCircle
					className="widget-info-icon"
					title="show info"
					onClick={handleOpen}
				/>
			</span>
			<div>
				<Modal className="rs-dialog" open={open} onClose={handleClose}>
					<Box className="rs-d-container">
						<div className="rs-widget-info">
							<div className="flex-column">
								<h6>{widget_config.title}</h6>
								<div>
								{!editMode && 
									<div className="flex-column widget-info-body-container">
										{/* <h6>Description</h6> */}
										<p>{widgetInfo.Description
											|| "* missing widget description"} </p>
										<button className="button-default widget-info-footer" title="Update Widget Info"
											onClick={e => setEditMode(true)}>
											Update
										</button>
									</div>
								}
								</div>
								<div>
									{editMode && 
									<div className="flex-column widget-info-body-container">
										{/* <h6>Update Widget Info</h6> */}
										<textarea
											className="textarea-widget-info"
											placeholder="Enter widget description *"
											onChange={(e) =>
												setWidgetInfo({ ...widgetInfo, Description: e.target.value })
											}
											value={widgetInfo.Description} />
										<br />
										<p ></p>
										{error && <span>operation failed</span>}
										<div className="widget-info-footer">
												<span className="button-default"
													onClick={clickHandler}>
													<span>Update</span>
												</span>
												<span className="button-default"
													onClick={() => setEditMode(false)}>
													Cancel
												</span>
											</div>
									</div>
									}
								</div>
							</div>
						</div>
					</Box>
				</Modal>
			</div>
		</>
	);
}

export default WidgetInfo;