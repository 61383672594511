import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "chart.js/auto";
import "./Css/Charts.css";
import { Bar } from "react-chartjs-2";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { FaExpandAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { MODAL_COLUMNS } from "./Constants/CustomDataGridConstants";

import WidgetInfo from "./Plugins/WidgetInfo";
import CustomLoadingIndicator from "./Plugins/CustomLoadingIndicator";

import { IsEmpty, Formatter } from "../Helpers/FormatHelper";
import { ShortenString } from "../Helpers/StringHelper";

export default function CustomBarChart({
  data,
  label,
  fields,
  style,
  widget_config
}) {
  const [dataValues, setDataValues] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const history = useNavigate();
  
  const [currentTab, setCurrentTab] = useState("Chart view");
  
  const defaultStyle = {
    margin: "auto"
  };

  function changeTab(tab) {
    setCurrentTab(tab);
  }

  style = style ? style : defaultStyle;

  useEffect(() => {
    let result = widget_config.data_formatter(data, { dataColumn: [fields[0]], labelColumn: [fields[1]] });

    setDataValues(result.dataValue);
    setDataLabels(result.dataLabels);
  }, [data]);

  const valueAxis = {
    grid: {
      display: true,
    },
    ticks:
      {
        beginAtZero: true,
        callback: function (value, index, values) {
          return Formatter(value);
        },
        autoSkip: false,
      }
  }

  const labelAxis = {
    grid: {
      display: false,
    },
    ticks:
      {
        beginAtZero: true,
        callback: function (value, index, values) {
          return ShortenString(this.getLabelForValue(value), 12);
        },
        autoSkip: false,
      }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: widget_config.axis,
    scales: {
      yAxis: 
        widget_config.axis === "x" ? valueAxis : labelAxis,
      xAxis: 
        widget_config.axis === "x" ?  labelAxis : valueAxis
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  let dataset = {
    yValueFormatString: "$####.00",
    labels: [...dataLabels],
    datasets: [
      {
        data: [...dataValues],
        backgroundColor: ["#1ab394"],

        borderWidth: 1,
        borderColor: "white",
        hoverOffset: 10,
      },
    ],
    datalabels: {
      align: "end",
      anchor: "start",
    },
  };

  return (
    <Wrapper className="rs-widget">
      <div className="flex-column rs-widget-container">
        <div>
          <Modal className="rs-dialog"
              open={open} onClose={handleClose}>
            <Box className="flex-column rs-d-container widget-modal-body">
              <div>
                <h6>{label}</h6>
              </div>
              <div>
                { widget_config.tabs &&
                    <ul className="flex-row tab-ul">
                      { widget_config.tabs.map(function (tab) {
                          return (<li className={`tab-li ${ currentTab === tab 
                              ? 'tab-li-active' : null }`}>
                            <a onClick={() => changeTab(tab)}>{tab}</a>
                          </li>
                          );
                      })}
                    </ul>
                }
              </div>
              <div>
                {currentTab === "Table view" && (
                  <div className="table">
                    <DataGrid
                      style={{
                        background: "white",
                        fontFamily: '"Rubik", sans-serif',
                      }}
                      autoHeight
                      rows={data}
                      columns={MODAL_COLUMNS}
                      getRowId={(row) => row.rowNumber}
                      rowHeight={30}
                      animateRows={true}
                    />
                  </div>
                )}
              </div>
              {currentTab === "Chart view" && (
                <div className="rs-w-b-container" style={style}>
                  <Bar options={options} data={dataset} />
                </div>
              )}
            </Box>
          </Modal>
        </div>
        <div className="rs-w-header flex-stretch">
          <div className="flex-row flex-space-between">
            <div className="rs-w-title">
              <div className="flex-row">
                <h6>
                  {widget_config.title + " "}
                </h6>
                <WidgetInfo widget_config={{
                    title: widget_config.title,
                    page: widget_config.page
                  }}/>
              </div>
            </div>
            <div className="rs-w-option">
              <div className="flex-row">
                {IsEmpty(data) && (
                  <CustomLoadingIndicator />
                )}
                {!IsEmpty(data) && (
                  <div>
                    {widget_config.showExpandAlt && (
                      <div>
                        <FaExpandAlt className="rs-w-expand-icon" onClick={handleOpen} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        
        <div className="rs-w-body flex-self-center rs-widget-body">
          <div className="flex-row rs-w-b-container">
            <Bar options={options} data={dataset} />
          </div>
        </div> 
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div``
