import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { TbStar, TbTriangleInverted, TbTriangle } from "react-icons/tb";
import "./Css/Kpi.css";
import "./Css/Widget.css";
import { Doughnut, Line } from "react-chartjs-2";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { FaExpandAlt } from "react-icons/fa";
import { getFanosData } from "../../Services/dashboardService";
import { KPI_MODAL_COLUMNS } from "./Constants/CustomDataGridConstants";
import {
  POSTIVE_KPI_COLOR_BAND,
  NEGATIVE_KPI_COLOR_BAND,
} from "./Constants/KpiChartConstants";
import { Formatter } from "../Helpers/FormatHelper.js";

import { GaugeNeedle } from "./Plugins/GaugeNeedle";
import CustomDataGrid from "./CustomDataGrid";
import WidgetInfo from "./Plugins/WidgetInfo";

import CustomLoadingIndicator from "./Plugins/CustomLoadingIndicator";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import { AiOutlineConsoleSql } from "react-icons/ai";

function KpiChartDagu({ kpiConfig }, ref) {
  const [apiData, setApiData] = useState([]);
  const [kpiData, setKpiData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [kpiColor, setKpiColor] = useState({});

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentTab, setCurrentTab] = useState("Chart");

  const detailStyle = {
    width: "80%",
    height: "90%",
  };

  function changeTab(tab) {
    setCurrentTab(tab);
  }

  // function getModalData() {
  //   let modalData = [];
  //   for (let index = 0; index < kpiData.dataSet.data.length; index++) {
  //     modalData.push({
  //       id: index,
  //       month: kpiData.dataSet.data[index],
  //       value: kpiData.dataSet.value[index] + " %",
  //     });
  //   }
  //   return modalData;
  // }

  useEffect(() => {
    function fetchPageData() {
      getFanosData(
        kpiConfig.kpi_api,
        kpiConfig.default_filter_params,
        kpiConfig.dataSource,
        kpiConfig.method,
        kpiConfig.convertParamsToFormData
      ).then((data) => {
        setApiData(data.data.model);
        let sum = 0;
        let res = data.data.model;
        let average = 0;
        let aggArray = [];
        let totalSum = 0;
        let result = res.reduce(function (r, a) {
          r[a.facilityId] = r[a.facilityId] || [];
          r[a.facilityId].push(a);
          return r;
        }, Object.create(null));
        let array = Object.entries(result);
        res?.map((el) => {
          el && (sum = sum + el[kpiConfig.field]);
        });
        if (kpiConfig.operationType === "Total") {
          setKpiData(sum.toFixed(2));
        } else if (kpiConfig.operationType === "Average") {
          res?.map((el) => {
            el && (average = ((el[kpiConfig.field] * 100) / sum).toFixed(2));
            setKpiData(+average);
          });
        } else if (kpiConfig.operationType === "Aggregate Percentile") {
          for (let i = 0; i < array.length; i++) {
            let summation = 0;
            for (let j = 0; j < array[i][1].length; j++) {
              if (array[i][1][j]?.soh > 0) {
                summation = summation + 1;
              }
            }
            // console.log(summation);
            let percentage = summation / array[i][1].length;
            aggArray.push(percentage);
          }
          aggArray.map((el) => {
            el && (totalSum = totalSum + el);
          });
          let productAvailability = (totalSum / array.length) * 100;
          setKpiData(+productAvailability.toFixed(2));
        } else if (kpiConfig.operationType === "mos Aggregate Percentile") {
          for (let i = 0; i < array.length; i++) {
            let singleMos = 0;
            let mos = [];
            for (let j = 0; j < array[i][1].length; j++) {
              if (array[i][1][j]?.soh > 0 && array[i][1][j]?.amcWdos > 0) {
                singleMos = array[i][1][j].soh / array[i][1][j].amcWdos;
                if (singleMos >= 2 && singleMos <= 4) {
                  mos.push(singleMos);
                }
              }
            }
            
            let percentage = mos.length / array[i][1].length;
            aggArray.push(percentage);
          }
          
          aggArray.map((el) => {
            el && (totalSum = totalSum + el);
          });
          let soh = (totalSum / array.length) * 100;
          setKpiData(+soh.toFixed(2));
        }
        setLoading(false);
      });
    }
    fetchPageData();
  }, []);

  useImperativeHandle(ref, () => ({
    reload(kpiParamsState) {
      setLoading(true);

      getFanosData(
        kpiConfig.kpi_api,
        kpiParamsState,
        kpiConfig.dataSource,
        kpiConfig.method,
        kpiConfig.convertParamsToFormData
      ).then((data) => {
        let sum = 0;
        let res = data.data.model;
        let average = 0;
        let aggArray = [];
        let totalSum = 0;
        let result = res.reduce(function (r, a) {
          r[a.facilityId] = r[a.facilityId] || [];
          r[a.facilityId].push(a);
          return r;
        }, Object.create(null));
        let array = Object.entries(result);
        res?.map((el) => {
          el && (sum = sum + el[kpiConfig.field]);
        });
        if (kpiConfig.operationType === "Total") {
          setKpiData(sum.toFixed(2));
        } else if (kpiConfig.operationType === "Average") {
          res?.map((el) => {
            el && (average = ((el[kpiConfig.field] * 100) / sum).toFixed(2));
            setKpiData(+average);
          });
        } else if (kpiConfig.operationType === "Aggregate Percentile") {
          for (let i = 0; i < array.length; i++) {
            let summation = 0;
            for (let j = 0; j < array[i][1].length; j++) {
              if (array[i][1][j]?.soh > 0) {
                summation = summation + 1;
              }
            }
            let percentage = summation / array[i][1].length;
            // console.log(summation);
            aggArray.push(percentage);
            
          }
          aggArray.map((el) => {
            el && (totalSum = totalSum + el);
          });
          let productAvailability = (totalSum / array.length) * 100;
          setKpiData(+productAvailability.toFixed(2));
          
        } else if (kpiConfig.operationType === "mos Aggregate Percentile") {
          for (let i = 0; i < array.length; i++) {
            let singleMos = 0;
            let mos = [];
            for (let j = 0; j < array[i][1].length; j++) {
              if (array[i][1][j]?.soh > 0 && array[i][1][j]?.amcWdos > 0) {
                singleMos = array[i][1][j].soh / array[i][1][j].amcWdos;
                if (singleMos >= 2 && singleMos <= 4) {
                  mos.push(singleMos);
                }
              }
            }
            let percentage = mos.length / array[i][1].length;
            aggArray.push(percentage);
          }
          aggArray.map((el) => {
            el && (totalSum = totalSum + el);
          });
          let soh = (totalSum / array.length) * 100;
          setKpiData(+soh.toFixed(2));
        }
        setLoading(false);
      });
    },
  }));

  if (isLoading) {
    return (
      <div className="kpi-item">
        <div className="kpi-header-container">
          <span className="kpi-header">{kpiConfig.title}</span>
          <CustomLoadingIndicator />
        </div>
      </div>
    );
  } else {
    const config = {
      responsive: true,
      circumference: 180,
      rotation: -90,
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    const data = {
      labels: ["current", "Off Target"],
      datasets: [
        {
          label: "My First Dataset",
          data: [
            kpiData,
            kpiConfig.target ? Math.abs(kpiConfig.target - kpiData) : null,
          ],
          needleValue: kpiData,
          backgroundColor: ["#3288bd", "#d53e4f"],
          hoverOffset: 4,
        },
      ],
    };

    return (
      <>
        <div className="kpi-item">
          <div>
            {/* {!kpiConfig.rowDataSpecified && (
              <Modal className="rs-dialog"
                  open={open} onClose={handleClose}>
                <Box className="widget-modal-body">
                  <div>
                    <div className="kpi-header-container">
                      <span className="kpi-header">{kpiConfig.title}</span>
                    </div>
                    <Doughnut
                      options={config}
                      data={data}
                      plugins={[
                        GaugeNeedle("kpi-current", kpiData, data, {
                          text: "current",
                          unit: kpiConfig.unit,
                        }),
                      ]}
                    />
                  </div>
                </Box>
              </Modal>
            )} */}
            {kpiConfig.rowDataSpecified && (
              <Modal className="rs-dialog"
                  open={open} onClose={handleClose}>
                <Box
                  className="rs-d-container widget-modal-body"
                  style={currentTab === "Detail" ? detailStyle : {}}
                >
                  <div>
                    <div>
                      {kpiConfig.tabs && (
                        <ul className="flex-row tab-ul">
                          {kpiConfig.tabs.map(function (tab) {
                            return (
                              <li
                                className={`tab-li ${
                                  currentTab === tab ? "tab-li-active" : null
                                }`}
                              >
                                <a onClick={() => changeTab(tab)}>{tab}</a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      <div className="kpi-header-container">
                        <span className="kpi-header">
                          {kpiConfig.title}
                        </span>
                      </div>
                    </div>
                    {currentTab === "Chart" && (
                      <div>
                        <div className="Kpi-modal-chart">
                          <Doughnut
                            options={config}
                            data={data}
                            plugins={[
                              GaugeNeedle("kpi-current", kpiData, data, {
                                text: "current",
                                unit: kpiConfig.unit,
                              }),
                            ]}
                          />
                        </div>
                      </div>
                    )}
                    {currentTab === "Tabular" && (
                      <CustomDataGrid
                        component_config={{
                          columns: kpiConfig.columnDef,
                          data_source_api: kpiConfig.rowDataApi,
                          data_source: "fanos",
                          convert_params_to_formData: false,
                          data_property: "Data",
                          method: "GET",
                          select_filter_config: [],
                          defaultFilterParams: {},
                          legend: null,
                          api_data: apiData
                        }}
                      />
                    )}
                  </div>
                </Box>
              </Modal>
            )}
          </div>
          <div className="kpi-header-container">
            <span style={{ display: "inline-block"}} className="kpi-header">
              {kpiConfig.title + " "}            
              <WidgetInfo widget_config={{
                title: kpiConfig.title,
                page: kpiConfig.page
              }}/>
            </span>
            <div>
              <div>
                <FaExpandAlt className="expand-icon-2" onClick={handleOpen} />
              </div>
            </div>
            {/* <span><TbStar size={20} style={{ fill: "green", color:"green", verticalAlign:"baseline" }}/></span> */}
          </div>
          <div className="kpi-body-container">
            <div className="kpi-body-1">
              <div className="kpi-body-1-container">
                <div className="kpi-body-1-1">
                  <Doughnut
                    options={config}
                    data={data}
                    plugins={[
                      GaugeNeedle("kpi-current", kpiData, data, {
                        text: "Current",
                        unit: kpiConfig.unit,
                      }),
                      GaugeNeedle("kpi-target", kpiConfig.target, data, {
                        text: "Target",
                        dashed: true,
                        unit: kpiConfig.unit,
                        textYOffset: 40,
                      }),
                    ]}
                  />
                </div>
                <div className="kpi-body-1-2">
                  {kpiData < kpiConfig.target || (
                    <span>
                      <TbTriangle
                        size={20}
                        style={{
                          fill: "#d53e4f",
                          color: "#d53e4f",
                          verticalAlign: "baseline",
                        }}
                      />{" "}
                    </span>
                  )}
                  {kpiData > kpiConfig.target || (
                    <span>
                      <TbTriangleInverted
                        size={20}
                        style={{
                          fill: "#99d594",
                          color: "#99d594",
                          verticalAlign: "baseline",
                        }}
                      />{" "}
                    </span>
                  )}
                  <span className="kpi-value">
                    {" "}
                    {Math.abs(kpiData - kpiConfig.target).toFixed(1)} %
                  </span>
                  <br></br>
                  <span>vs. target</span>
                  <br></br>
                  {/* <span> month</span> */}
                </div>
              </div>
            </div>
            {/* {kpiConfig.rowDataSpecified && (
              <div className="kpi-body-2">
                <Line
                  data={state}
                  options={{
                    scales: {},
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            )} */}
          </div>
        </div>
      </>
    );
  }
}

export default React.forwardRef(KpiChartDagu);
