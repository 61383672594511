import {
  DefaultDateFormatter,
} from "../../Helpers/FormatHelper.js";

export const SYNCED_FACILITIES_COLUMNS = [
    {
      field: "institution",
      headerName: "Institution",
      flex: 2.5,
      minWidth: 250,
    },
    {
      field: "regionName",
      headerName: "Region",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "zoneName",
      headerName: "Zone",
      flex: 1,
      minWidth: 100,
    },
    {
        field: "lastSyncDate",
        headerName: "Last Sync Date",
        flex: 1,
        minWidth: 100,
        type: "date",
        valueFormatter: (params) => {
          return DefaultDateFormatter(params?.value);
        },
        colorCodeStatusCol: "CalculatedStatus",
    }
];

export const DAGU_FACILITIES = [
  {
    field: "Name of Facility",
    headerName: "Institution",
    flex: 2.5,
    minWidth: 300,
  },
  {
    field: "Type",
    headerName: "Type",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "Region",
    headerName: "Region",
    flex: 1,
    minWidth: 100,
  }
];

export const IMPORTED_AMOUNT = [
  {
    field: "currency",
    headerName: "Currency",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "value",
    headerName: "Amount",
    flex: 1,
    minWidth: 300,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      const valueFormatted = Number(params.value).toLocaleString();
      return `${valueFormatted}`;
    },
  },
];

const CUSTOM_CARD_CONSTANTS = [ ];

export default CUSTOM_CARD_CONSTANTS;

  