import React, { useEffect, useState } from "react";
import { appActions } from "../../app.state";
import { useNavigate, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { selectors } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import logo from "../../Assets/Images/logo-svg.svg";
import { HiOutlineLogout, HiUserGroup } from "react-icons/hi";
import SideNav from "./SideNav2";

function Navbar() {
  const locationPath = useLocation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const history = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const app = useSelector(selectors.app);
  const ToggleSidebar = () => {
    isOpen === true ? setIsOpen(false) : setIsOpen(true);
    dispatch(appActions.collapseSideNav(!app.collapseSideNav));
  };

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    history("/login");
  };

  const openAdminPage = () => {
    history("/admin");
  };

  useEffect(() => {
    setIsOpen(false);
  }, [locationPath]);

  return (
    <Wrapper>
      { userInfo?.Username && locationPath?.pathname != "/login"  && <>

      { isOpen && 
      <div className="sidebar-menu">
        <div className="flex-column">
          <div>
            <div className="" >
              { userInfo?.Username && 
                <GiHamburgerMenu
                  className="toggle-icon"
                  onClick={ToggleSidebar}
                  size={30}
                />
              }
              <img src={logo} alt="" className="nav-logo" />
            </div>
          </div>
          <div>
            <SideNav />
        </div>
        </div>
      </div> }
      <nav className="navbar navbar-light shadow-md" 
          style={{ boxShadow: "grey 0px 2px 5px 0px", position: "fixed" }}>
        <div className="flex-row nav-bar">
          <div className="nav-left" >
            { userInfo?.Username && 
              <GiHamburgerMenu
                className="toggle-icon"
                onClick={ToggleSidebar}
                size={30}
              />
            }
            <img src={logo} alt="" className="nav-logo" />
          </div>
          <div className="nav-right">
            <div className="flex-row nav-right-container">
              <div className="nav-title">
                <p className="title">
                  National Supply Chain Dashboard
                </p>
              </div>
              <div className="nav-user-info">
                { userInfo?.Username && locationPath.pathname != "/login" &&
                  <div className="flex-row nav-user-info-container">
                    <span className="user-info">
                      <span title={userInfo?.Username}> 
                        {userInfo?.Username.split(" ")[0]} 
                      </span>
                      { (userInfo?.UserTypes?.filter(userType => { 
                            return userType.UserType === "Super Admin"
                          })?.length > 0)
                            && <span onClick={openAdminPage}>
                                  <span className="horizontal-separator"> | </span>
                                  <HiUserGroup size={25} 
                                    title="User Management"
                                    className="navbar-icon"/>
                                </span> }          
                      <span onClick={handleLogout}>
                        <span className="horizontal-separator"> | </span>
                        <HiOutlineLogout size={25} 
                            title="Log out"
                            className="navbar-icon"/>
                      </span>
                    </span>
                  </div>
                }
              </div>
            </div>
          </div>
          
        </div>
      </nav>
      </> }
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;

  .nav-bar {
    padding: 0 1rem 0.5rem 1rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.25;
    color: white;
  }
  .nav-right-container {
    align-items: center;
  }
  .nav-right {
    flex: 1;
  }
  .nav-logo{
    height: 3rem;
    position: relative;
    color: #2955A2;
    top: 8px;
    margin: 0px 2rem;
  }
  .sidebar-menu .nav-logo{
    margin: 0;
  }
  .toggle-icon {
    margin: 0 0.5em;
  }
  .nav-title {
    flex: 1;
  }
  .nav-title > p {
    font-size: 1.5rem;
  }

  .user-info {
    cursor: pointer;
    font-size: 12px;
  }

  .sidebar-menu {
    padding: 0.5rem 0 0 1rem;
    z-index: 1000; 
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    color: white;
    background-color: #21618c;
    width: 256px;
    height: 100%;
  }

  @media screen and (max-width: 1025px) {
    .nav-title {
      font-size: 17px;
    }
  }
  @media screen and (max-width: 750px) {
    .title {
      display: none;
    }
    .nav-logo{
      margin: 0;
    }
  }
`;
export default Navbar;
