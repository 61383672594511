import React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Manufacturer from "../../Assets/GIF/Manufacturer.gif";
import MedicalEquipment from "../../Assets/GIF/Medical Equipment.gif";
import Pharmacy from "../../Assets/GIF/Pharmacy.gif";
import SupplyChain from "../../Assets/GIF/Supply chain.gif";

export default function Carousel() {
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const variants = {
    hidden: { opacity: 0, scale: 0.1 },
    visible: { opacity: 1, scale: 0.6 },
  };

  const Slide1 = ({ message }) => (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.7, type: "spring", stiffness: 100 }}
    >
      <p className="message">{message}</p>
    </motion.div>
  );
  const Slide2 = ({ message }) => (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.7 }}
    >
      <p className="message">{message}</p>
    </motion.div>
  );

  const Slide3 = ({ message }) => (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.7 }}
    >
      <p className="message">{message}</p>
    </motion.div>
  );
  const Slide4 = ({ message }) => (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.7 }}
    >
      <p className="message">{message}</p>
    </motion.div>
  );
  const Slide5 = ({ message }) => (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.7 }}
    >
      <p className="message">{message}</p>
    </motion.div>
  );

  useEffect(() => {
    const carouselTimeOut = setTimeout(() => {
      if (currentCarouselIndex === 3) {
        setCurrentCarouselIndex(0);
      } else {
        setCurrentCarouselIndex(currentCarouselIndex + 1);
      }
    }, 5000);

    return () => {
      clearTimeout(carouselTimeOut);
    };
  }, [currentCarouselIndex]);
  return (
    <Wrapper>
      <div className="flex-column">
        <div className="slides">
          <div className="flex-column slides-container">
            { currentCarouselIndex === 0 && (
              <div>
                <h4>EFDA</h4>
                <Slide1
                  message="This dashboard presents information on the proportion of registered and imported health 
                  products, the proportion of tested products, the proportion of licensed health product establishments, 
                  and the average processing time for product registration.
                  "
                />
              </div>
            )}
            {currentCarouselIndex === 1 && (
              <div>
                <h4>Supply Chain</h4>
                <img className="slide-img" src={SupplyChain} alt="SupplyChain" />

                <Slide2
                  message="This dashboard displays procurement and inventory metrics, including procurement cycle duration, status overview, 
                      national stock level, product expiry rate, health facility wastage rate, RRF submission rate, on-time facility refilling 
                      rate, and distribution status of medicine and medical devices."
                />
              </div>
            )}
            {currentCarouselIndex === 2 && (
              <div>
                <h4>Medical Equipment</h4>
                <img
                  className="slide-img"
                  src={MedicalEquipment}
                  alt="MedicalEquipment"
                />

                <Slide3
                  message="This dashboard provides information on the availability of medical devices in accordance with national standards, 
                  as well as their status at healthcare facilities. It also presents the proportion of facilities with updated medical device 
                  inventory and the proportion of health facilities that practice scheduled preventive maintenance."
                />
              </div>
            )}
            {currentCarouselIndex === 3 && (
              <div>
                <h4>Pharmacy Service</h4>
                <img className="slide-img" src={Pharmacy} alt="pharmacy" />
                <Slide4
                  message="This dashboard provides information on the functionality of clinical pharmacy services 
                      and Drug and Therapeutic Committees, the proportion of clients who receive all prescribed medications, 
                      and the proper utilization of health commodities distributed to health facilities."
                />
              </div>
            )}
            {currentCarouselIndex === 4 && (
              <div>
                <h4>Local Mannufacturer</h4>
                <img className="slide-img" src={Manufacturer} alt="Manufacture" />

                <Slide5
                  message="This dashboard provides information about the Number of Production Lines, 
                  Pharmaceutical Production Capacity Utilization, and Local market share"
                />
              </div>
            )}
          </div>
        </div>
        <div className="dotes">
          <section className="dotes-wrapper">
            <DottedNav isActive={currentCarouselIndex === 0} />
            <DottedNav isActive={currentCarouselIndex === 1} />
            <DottedNav isActive={currentCarouselIndex === 2} />
            <DottedNav isActive={currentCarouselIndex === 3} />
            <DottedNav isActive={currentCarouselIndex === 4} />
          </section>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .dotes-wrapper {
    display: flex;
    min-width: 20rem;
    justify-content: center;
    align-items: center;
    height: 14px;
  }
  h4 {
    color: #0f52ba;
    text-align: center;
  }
  p {
    font-size: 24px;
    color: grey;
    text-align: center;
    min-width: 20rem;
    max-width: 40rem;
  }
  .message {
    color: var(--bs-body-color);
  }
  .slides-container {
    margin: auto;
    text-align: center;
  }

  .slide-img {
    width: 9rem;
    height: 9rem;
    text-align: center;
  }

  @media screen and (max-width: 750px) {  
      border-top: 1px solid darkgray;
      border-bottom: 1px solid darkgray;
      padding: 1rem 0;
  }
`;
const DottedNav = styled.div`
  width: 14px !important;
  height: 14px;
  border-radius: 10px;
  background-color: ${({ isActive }) => (isActive ? "#0f52ba" : "grey")};
  margin: 0px 10px;
`;
