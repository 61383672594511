import React, { useEffect, useState } from "react";
import styled from "styled-components";

import "chart.js/auto";
import "./Css/Charts.css";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FaExpandAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import WidgetInfo from "./Plugins/WidgetInfo";

export default function DoughnutChart({
  data,
  label,
  fields,
  style,
  chart_options,
  hasTableView,
}) {
  const [recievedData, setReceivedData] = useState([]);
  const [recievedLabel, setReceivedLabel] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [hasTable, setHasTable] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useNavigate();

  const defaultStyle = {
    margin: "auto"
  };

  style = style ? style : defaultStyle;

  useEffect(() => {
    setLoading(true);
    const dataValue = [];
    const dataLabel = [];
    data &&
      data.map((element) => {
        element && dataValue.push(parseInt(element[fields[0]]));
        element && dataLabel.push(element[fields[1]]);
        setLoading(false);
      });

    setReceivedData(dataValue);
    setReceivedLabel(dataLabel);

    // console.log(data);
  }, [data]);

  const options = {
    responsive: true,
    maintainAspectRation: false,

    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(1) + "%";
          return percentage;
        },
        display: "auto",
        color: "white",
      },
      legend: {
        position: "bottom",
      },
      labels: {
        render: "percentage",
        fontSize: "bolder",
        position: "outside",
      },
    },
  };

  const dataset = {
    yValueFormatString: "$####.00",
    labels: [...recievedLabel],
    datasets: [
      {
        label: { label },
        data: [...recievedData],
        backgroundColor: [
          "#3288bd",
          "#fee08b",
          "#99d594",
          "#fc8d59",
          "#acd2ed",
          "#c94057",
          "#001524",
          "#73605b",
          "#e4ea8c",
          "#4d648d",
          "#265c00",
        ],

        borderWidth: 1,
        borderColor: "white",
        hoverOffset: 10,
      },
    ],
    datalabels: {
      align: "end",
      anchor: "start",
    },
  };
  return (
    <Wrapper className="rs-widget">
      <div className="flex-column rs-widget-container">
        <div>
          <Modal className="rs-dialog"
                  open={open} onClose={handleClose}>
            <Box className="flex-column rs-d-container">
              <h6>{label}</h6>
              {hasTableView && (
                <div>
                  {!hasTable && (
                    <button
                      className="chart-btn"
                      onClick={() => setHasTable(!hasTable)}
                    >
                      Table view
                    </button>
                  )}

                  {hasTable && (
                    <button
                      className="chart-btn"
                      onClick={() => setHasTable(!hasTable)}
                    >
                      Chart view
                    </button>
                  )}
                </div>
              )}

              {hasTable === true && (
                <table className="table table-bordered">
                  <tr>
                    <th>Product Type</th>
                    <th>Count</th>
                    <th>Percentage</th>
                  </tr>
                  {data.map((el) => (
                    <tr>
                      {" "}
                      <td>{el.product_type}</td>
                      <td>{el.count}</td>
                      <td> {el.percentage}</td>
                    </tr>
                  ))}
                </table>
              )}
              {hasTable === false && (
                <div className="rs-w-body flex-self-center rs-widget-body" style={style}>
                  <div className="flex-row rs-w-b-container">
                    <Doughnut
                      type="doughnut"
                      options={options}
                      data={dataset}
                      plugins={[ChartDataLabels]}
                    />
                  </div>
                </div>
              )}
            </Box>
          </Modal>
        </div>

        <div className="rs-w-header flex-stretch">
          <div className="flex-row flex-space-between">
            <div className="rs-w-title">
              <div className="flex-row">
                <h6>
                  {chart_options.title + " "}
                  <WidgetInfo widget_config={{
                    title: chart_options.title,
                    page: chart_options.page
                  }}/>
                </h6>
              </div>
            </div>
            <div className="rs-w-option">
              <div className="flex-row">
                {isLoading && (
                  <div>
                    <div
                      style={{ color: "#3288bd" }}
                      className="css-at12u2-loadingIndicator"
                      aria-hidden="true"
                    >
                      <span className="css-3-Loading-dot"></span>
                      <span className="css-2-Loading-dot"></span>
                      <span className="css-1-Loading-dot"></span>
                    </div>
                  </div>
                )}
            
                {!isLoading && (
                  <div>
                    {chart_options.showExpandAlt && (
                      <div className="rs-w-expand-icon">
                        <FaExpandAlt onClick={handleOpen} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      
        <div className="rs-w-body flex-self-center rs-widget-body" style={style}>
          <div className="flex-row rs-w-b-container">
            <Doughnut
              type="doughnut"
              data={dataset}
              plugins={[ChartDataLabels]}
              options={options}
            />
          </div>
        </div>

        <div className="rs-w-footer">
          <div className="flex-row flex-end">
            {chart_options.showViewMore && (
              <div className="rs-w-link-external">
                <span onClick={() => history("/Regulatory/RegisteredProducts")}>
                  View More
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div``
