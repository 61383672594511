
export function StringMatch (str1, str2) {
     return str1.replace(" ", "").toLowerCase() 
            === str2.replace(" ", "").toLowerCase();
}

export function StringMatchStartsWith (str, start) {
    if(str.length > 12)
        return str.replace(" ", "").toLowerCase().startsWith(start.replace(" ", "").toLowerCase());
    else
        return StringMatch (str, start);
}

export function ShortenString (str, characterLimit) {
    if (str.length >= characterLimit) {
        return (
            str.slice(0, str.length)
            .substring(0, characterLimit - 1)
            .trim() + "..."
        );
    }

    return str;
}

const StringHelper = [
  
]

export default StringHelper;
