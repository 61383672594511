
import supplyChain from "../../Assets/vectors/supplyChainVector.png";
import pharmacy from "../../Assets/vectors/pharmacyVector.png";
import medical from "../../Assets/vectors/medicalVector.png";
import manufacturer from "../../Assets/vectors//manufacturerVector.png";

export const EFDA_INFO = {
  title: "EFDA",
  description: `This dashboard presents information on the proportion of registered and imported 
      health products, the proportion of tested products, the proportion of licensed health 
      product establishments, and the average processing time for product registration.`,
  icon_src: supplyChain
}

export const PHARMACY_SERVICE = {
  title: "Pharmacy service",
  description: `This dashboard provides information on the functionality of clinical pharmacy services and 
      Drug and Therapeutic Committees, the proportion of clients who receive all prescribed medications, 
      and the proper utilization of health commodities distributed to health facilities.`,
  icon_src: pharmacy
}

export const EPSS_INFO = {
  title: "Supply Chain",
  description: `This dashboard displays procurement and inventory metrics, including procurement 
      cycle duration, status overview, national stock level, product expiry rate, health facility 
      wastage rate, RRF submission rate, on-time facility refilling rate, and distribution status 
      of medicine and medical devices.`,
  icon_src: supplyChain
}

export const LOCAL_MANUFACTURER_INFO = {
  title: "Local manufacturer",
  description: `This dashboard provides information about the Number of
      Production Lines, Pharmaceutical Production Capacity
      Utilization, and Local market share`,
  icon_src: manufacturer
};

export const MEDICAL_EQUIPMENT_INFO = {
  title: "Medical Equipment",
  description: `This dashboard provides information on the availability
      of medical devices in accordance with national standards,  as well 
      as their status at healthcare facilities. It also presents the 
      proportion of facilities with updated medical device inventory and 
      the proportion of health facilities that practice scheduled 
      preventive maintenance.`,
  icon_src: medical
}

const LandingInfoConstant = [ EFDA_INFO, PHARMACY_SERVICE, 
    EPSS_INFO, LOCAL_MANUFACTURER_INFO, MEDICAL_EQUIPMENT_INFO
];

export default LandingInfoConstant;
